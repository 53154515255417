import React from "react";
import classes from "./tabbar.module.css";
import TabItem from "./TabItem";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as ChatIcon } from "./../assets/chat_filled.svg";
// import { ReactComponent as BlankChatIcon } from "./../assets/chat_blank.svg";
import { ReactComponent as MenuNavIcon } from "./../assets/menu-nav.svg";
import { ReactComponent as CloseIcon } from "./../assets/close-blue.svg";
import useScrollLock from "./../context/useScrollLock";

export default function TabBar(props) {
  const { activeTabIndex, tabChangeHandler, isVissibleTest } = props;
  const [isVissible, vissibleToggler] = React.useState(isVissibleTest);

  return (
    <div className={classes.tabBar}>
      <AnimatePresence>
        {isVissible && (
          <FixedNavBarOverlay
            toggler={vissibleToggler}
            tabChangeHandler={tabChangeHandler}
            activeTabIndex={activeTabIndex}
          />
        )}
      </AnimatePresence>
      <span className={classes.tabNavBtn} id="tabBarToggle" name="tabBarToggle" data-testid="tabBarToggle" onClick={() => vissibleToggler(true)}>
        <MenuNavIcon />
      </span>
      <div
        className={`${classes.tabItemWrapper} ${
          activeTabIndex === 1 && classes.ActivetabItemWrapper
          }`}
      // data-activeIndex={activeTabIndex}
      >
        <TabItem
          isActive={activeTabIndex === 1}
          onClick={() => tabChangeHandler(1)}
          id="tabChange"
          name="tabChange"
          data-testid="tabChange"
        >
          <ChatIcon width="22px" height="22px" />
          <p>DAR VOZ</p>
        </TabItem>
        {/* <TabItem
          ownClass="2ndBar"
          isActive={activeTabIndex === 2}
          onClick={() => tabChangeHandler(2)}
        >

         <BlankChatIcon
            className={classes.blankChatIcon}

            width="22px"
            height="22px"              
            />
            <p>PEDIR VOZ</p>
        </TabItem> */}
      </div>
      {/* <a href="/dashboard" className={classes.tabBarLink}>
        Volver al dashboard
      </a> */}
    </div>
  );
}

const FixedNavBarOverlay = (props) => {
  useScrollLock();
  const { tabChangeHandler, activeTabIndex } = props;

  const clickedNavHandler = (i) => {
    tabChangeHandler(i);
    props.toggler(false);
  };

  //Opción movil
  return (
    <motion.div
      className={classes.fixedNavBarOverlay}
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
    >
      <button
        data-testid="closeBtnOverlay"
        onClick={() => props.toggler(false)}
        className={classes.closeBtnOverlay}
      >
        <CloseIcon />
      </button>
      <nav className={classes.navBoxOverlay}>
        <div
          className={`${activeTabIndex === 1 && classes.clrNabBtnItem}`}
          onClick={() => clickedNavHandler(1)}
          data-testid="darVoz"
        >
          DAR VOZ
        </div>
        <div
          className={`${activeTabIndex === 2 && classes.clrNabBtnItem}`}
          onClick={() => clickedNavHandler(2)}
          data-testid="pedirVoz"
        >
          PEDIR VOZ
        </div>
      </nav>
    </motion.div>
  );
};
