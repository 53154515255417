import React from "react";
import classes from "./shared.module.css";
import ListItem from "./ListItem";
import { useSkillList } from "./../../context/useSkillList";

const SkillsList = (props) => {
  const { listTitle, color, list, category, iconSrc = "", validateTextAreaMessage } = props;
  const { onDragOver, onDrop } = useSkillList();

  const filteredList = list.filter((obj) => obj.category === category);

  const onDropSkill = (e, skillCategory) => {

    onDrop(e, skillCategory);
    validateTextAreaMessage();
  }
  return (
    <div
      className={classes.skillList}
      style={{ borderColor: color, backgroundColor: color }}
    >
      <div className={classes.listTitleWrapper}>
        <p>{listTitle}</p>
        <img
          src={iconSrc}
          width="24px"
          style={{ marginLeft: "8px" }}
          alt="emoji"
        />
      </div>
      <ul
        data-testid="drop-target"
        className={classes.ulWrapper + " styleScroll"}
        onDragOver={(e) => onDragOver(e)}
        onDrop={(e) => {
          onDropSkill(e, category);
        }}
      >
        {filteredList.map((obj, i) => {
          return <ListItem key={i} id={obj.id} itemTitle={obj.title} category={category} />;
        })}
      </ul>
    </div>
  );
};

export default SkillsList;
