import React from "react";
import classes from "./profileBox.module.css";
import classesShared from "./shared.module.css";

const ProfileBoxMisVoces = (props) => {
  const { palabra, nameVoz, urlFoto } = props;

  return (
    <div className={classes.searchItemVozDada}>
      <div style={{ margin: "16px 16px 16px 0px" }}>
        <div className={classesShared.iconUser}>
          <img src={urlFoto} alt="profile" className={classesShared.photoCell} />
        </div>
      </div>
      <div className={classes.srchTitleWraper} data-testid='nameVoz'>
        <p>Voz {palabra}:</p>
        <p>{nameVoz}</p>
      </div>
    </div>
  );
};

export default ProfileBoxMisVoces;
