import React, { useState } from "react";
import classes from "./giveVoice.module.css";
import { useParams } from 'react-router-dom';
import TabBar from "./shared/TabBar";
import TabContent from "./shared/TabContent";
import DarVozTab from "./Tabs/DarVozTab";
import { ProvideSkillList } from "./context/useSkillList";


const GiveVoice = (props) => {
  let { id } = useParams();
  const [whichTab, setWhichTab] = useState(1);

  // TAB CHANGE HANDLER
  // const tabChangeHandler = (i) => {
    // setWhichTab(i);
  // };
  return (
    <ProvideSkillList id={id}>
      <div className={classes.giveVoiceWrapper}>
        {/* <TabBar activeTabIndex={whichTab} tabChangeHandler={tabChangeHandler} /> */}
        <TabBar activeTabIndex={whichTab} isVissibleTest={false}/>
        <TabContent activeTabIndex={whichTab}>
          <DarVozTab tabIndex={whichTab} href="/givevoice" isCheckPillActive={false}/>
        </TabContent>
      </div>
    </ProvideSkillList>
  );
};
export default GiveVoice;
