import React from "react";
import classes from "./shared.module.css";
import { useSkillList } from "./../../context/useSkillList";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Zoom from '@material-ui/core/Zoom';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: 'rgba(222, 227, 255, 1)',
    color: 'rgba(41, 39, 48, 1)',
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    fontSize: 12,
    padding: 12,
  },
}))(Tooltip);

const Pill = (props) => {
  const { title = "", id, desc, move } = props;
  const { onDragStart } = useSkillList();
  return (
    <LightTooltip title={desc} arrow TransitionComponent={Zoom}>
      {move === 'y' ?
        <button
          name={title + "_button"}
          className={classes.pillButton}
          // onClick={() => addSkillHandler(title)}
          onDragStart={(e) => onDragStart(e, id, title)}
          draggable
        >
          {title}
        </button>
        :
        <button
          className={classes.pillButton2}
        >
          {title}
        </button>
      }
    </LightTooltip>
  );


};

export default Pill;