import React, { useContext } from "react";
import { vozavosContext } from "../../../vozavosContext";
import { NavLink, Redirect } from "react-router-dom";
import error500 from "./assets/error-500.svg";
import "./pagError.css";

const PagServerError = () => {
    const {
        state: { renderDashboard },
        actions: { setRenderDashboard },
    } = useContext(vozavosContext);

    const redirectDashboard = () => {
        setRenderDashboard(!renderDashboard);
        return <Redirect push to="/dashboard" />
    };

    return (
        <div className="container parentDiv">
            <div className="row flexPageError">
                <div className="col-lg-6 col-md-6 col-sm-12 flexImg">
                    <img alt="página no encontrada" src={error500}></img>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 flexTxt">
                    <p>Oh oh…</p>
                    <p>Ha ocurrido un error de servidor, intenta refrescar tu navegador o regresa al inicio</p>
                    <NavLink to="/">
                        <button data-testid="botonRegresar" id="botonRegresar" className="dashboardBtn" onClick={() => redirectDashboard()}>Regresar al inicio</button>
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default PagServerError;