import React from "react";
import { Chart, Series, CommonSeriesSettings, Legend, Tooltip, Title, ArgumentAxis, Label, LoadingIndicator, Margin } from "devextreme-react/chart";
import "./newgraph.css";

const tooltipTemplate = (pointInfo) => {
  return (
    <div>
      {pointInfo.valueText}/{pointInfo.total}
    </div>
  );
};

const NewGraph = (props) => {
  return (
    <div className="col-md-12">
      <Chart id="chart" dataSource={props.dataSource} barGroupPadding={0}>
        <Title text="Tus competencias" horizontalAlignment="left" verticalAlign="top">
          <Margin left="20" top="25" bottom="50"></Margin>
        </Title>
        <CommonSeriesSettings argumentField="state" type="fullstackedbar" barPadding={0.5} barWidth={35} fullstackedarea />
        <LoadingIndicator enabled={true} text="Cargando..." />
        <Series
          valueField="maleyoung"
          name="Experto"
          stack="e"
          color="#4D4CAC"
        />
        <Series
          valueField="malemiddle"
          name="Optimo"
          stack="e"
          color="#5D7FF1"
        />
        <Series
          valueField="malehalf"
          name="Adecuado"
          stack="e"
          color="#FE9900"
        />
        <Series
          valueField="maleolder"
          name="Para desarrollar"
          stack="e"
          color="#FF808B"
        />
        {/* <Export enabled={true} /> */}
        <ArgumentAxis>
          <Label
            wordWrap="normal"
            textOverflow="ellipsis"
            overlappingBehavior="none"
          />
        </ArgumentAxis>
        <Legend verticalAlignment="top" horizontalAlignment="center" visible={false} />
        <Tooltip className="tooltip" enabled={true} contentRender={tooltipTemplate} />
      </Chart>
    </div>
  );
}
export default NewGraph;
