import React, { useContext, useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ReactComponent as Dashboard } from "./../home/assets/poll-24px.svg";
import { ReactComponent as Pediricon } from "./../home/assets/dar-voz.svg";
import { ReactComponent as Pediricon2 } from "./../home/assets/dar-voz2.svg";
// import { ReactComponent as Companion } from "./../home/assets/companeros-icon.svg";
import { ReactComponent as Recordvoice2 } from "./../home/assets/mis-voces-lila.svg";
import { ReactComponent as Recordvoice } from "./../home/assets/misvoces-activo.svg";
import { ReactComponent as BlankChatIcon } from "../giveVoiceComp/assets/pedir-voz.svg";
import exit from "./../home/assets/exit_to_app-24px.svg";
import { getUserPhotoHome } from "../../assets/api";
//import useWindowSize from "../../useWindowSize";

import { vozavosContext } from "../vozavosContext";
import "./../sidebar/sidebar.css";

const Sidebar = () => {
  const {
    state: { userName, msal, client, renderDashboard, renderAskVoice, renderGiveVoice, renderMyVoices }, //mail
    actions: { setRenderDashboard, setRenderAskVoice, setRenderGiveVoice, setRenderMyVoices, setRenderMyVoicesReceived },
  } = useContext(vozavosContext);

  //const { width } = useWindowSize();

  const [backgRound, setbackgRound] = useState(true);
  const [backgRoundSecondList, setbackgRoundSecondList] = useState(false);
  const [backgRoundThirdList, setbackgRoundThirdList] = useState(false);
  const [backgRoundfourthList, setbackgRoundfourthList] = useState(false);
  const [foto, setFoto] = useState();

  let location = useLocation();
  useEffect(() => {

    setbackgRound(location.pathname.includes('/dashboard') || location.pathname.length === 1 ? true : false);
    setbackgRoundSecondList(location.pathname.includes('/givevoice') ? true : false);
    setbackgRoundThirdList(location.pathname.includes('/askvoice') ? true : false);
    setbackgRoundfourthList(location.pathname.includes('/myvoices') || location.pathname.includes('/voice') ? true : false);

    if (renderAskVoice) {
      thirdListHandler();
    }
    if (renderGiveVoice) {
      secondListHAndler();
    }
    if (renderMyVoices) {
      fourthListHandler();
    }
    if (renderDashboard) {
      backgRoundHandler();
    }
  }, [renderDashboard, renderAskVoice, renderGiveVoice, renderMyVoices]);

  useEffect(() => {
    async function loadMePhoto() {
      var photo = await getUserPhotoHome(client);
      setFoto(photo);
    }
    loadMePhoto();
  }, [])

  let arrayNames = userName.replace(" de "," ").replace(" del "," ").replace(" los "," ").replace(" De "," ").replace(" Del "," ").replace(" Los "," ").split(" ");
  let nombreCompleto = "";

  if (arrayNames.length === 4)
    nombreCompleto = arrayNames[0] + " " + arrayNames[2];
  else nombreCompleto = arrayNames[0] + " " + arrayNames[1];

  const backgRoundHandler = () => {
    setbackgRound(true);
    setbackgRoundSecondList(false);
    setbackgRoundThirdList(false);
    setbackgRoundfourthList(false);
    setRenderDashboard(!renderDashboard);
  };
  const secondListHAndler = () => {
    setbackgRound(false);
    setbackgRoundSecondList(true);
    setbackgRoundThirdList(false);
    setbackgRoundfourthList(false);
    setRenderGiveVoice(!renderGiveVoice);
  };

  const thirdListHandler = () => {
    setbackgRound(false);
    setbackgRoundSecondList(false);
    setbackgRoundThirdList(true);
    setbackgRoundfourthList(false);
    setRenderAskVoice(!renderAskVoice);
  };

  const fourthListHandler = () => {
    setbackgRound(false);
    setbackgRoundSecondList(false);
    setbackgRoundThirdList(false);
    setbackgRoundfourthList(true);
    setRenderMyVoices(!renderMyVoices);
    setRenderMyVoicesReceived(false);
  }

  return (
  
    <div className="maindivSidebar">
      <div className="ProfileDiv">
        <div className="ProfilepicStyle">
          <div className="iconUser">
            <img id="photoMe" src={foto} alt="" />
          </div>
        </div>
        <div className="userNamestyle" name="userNamestyle">{nombreCompleto}</div>
        <div className="userText1"></div>
        <div className="userText2">{/* Descripción persona */}</div>
      </div>

      <ul className="ulList">
        <NavLink to="/dashboard" activeClassName="navItem-active">
          <li className={backgRound ? "firstListBckRoud" : "firstList"} data-testid="dash" onClick={backgRoundHandler}>
            <span className={backgRound ? "firstlishIcon" : "firstlistIcon"}>
              <Dashboard />
            </span>
            <span name="Dashboard" className={backgRound ? "spanTextfirstListNested" : "spanTextfirstList"}>Dashboard</span>
          </li>
        </NavLink>
        <NavLink to="/givevoice" activeClassName="navItem-active" data-testid="ActiveGiveVoice">
          <li className={backgRoundSecondList ? "firstListBckRoud" : "firstList"} data-testid="givevoice" onClick={secondListHAndler}>
            <span className={backgRoundSecondList ? "firstlishIcon" : "firstlistIcon"}>
              {backgRoundSecondList ? <Pediricon /> : <Pediricon2 />}
            </span>
            <span name="Dar Voz" className={backgRoundSecondList ? "spanTextfirstListNested" : "spanTextfirstList"}>Dar Voz</span>
          </li>
        </NavLink>
        <NavLink to="/askvoice" activeClassName="navItem-active" data-testid="ActiveAskVoice">
          <li className={backgRoundThirdList ? "firstListBckRoud" : "firstList"} data-testid="thirdListHandler" onClick={thirdListHandler}>
            <span className={backgRoundThirdList ? "firstlishIcon" : "firstlistIcon"}>
              <BlankChatIcon />
            </span>
            <span name="Pedir Voz" className={backgRoundThirdList ? "spanTextfirstListNested" : "spanTextfirstList"}>Pedir Voz</span>
          </li>
        </NavLink>
        <NavLink to="/myvoices" activeClassName="navItem-active" data-testid="ActiveMyVoices">
          <li className={backgRoundfourthList ? "firstListBckRoud" : "firstList"} data-testid="myVoices" onClick={fourthListHandler}>
            <span className={backgRoundfourthList ? "firstlishIcon" : "firstlistIcon"}>
              {backgRoundfourthList ? <Recordvoice /> : <Recordvoice2 />}
            </span>
            <span name="Mis voces" className={backgRoundfourthList ? "spanTextfirstListNested" : "spanTextfirstList"}>Mis voces</span>
          </li>
        </NavLink>
      </ul>

      <img className="iconExit" src={exit} alt="Salir" />
      <span type="button" className="goutTextStyl" data-testid="logout" name="goutTextStyl" onClick={() => msal.logout()}>Salir</span>

    </div>
  );
};

export default Sidebar;