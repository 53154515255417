import React from "react"
import ContentLoader from "react-content-loader"

const YourAbilitiesLoader = (props) => (
    <ContentLoader
        speed={2}
        width={1019}
        height={172}
        viewBox="0 0 1019 172"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        title="Cargando tus habilidades..."
        style={{ width: '100%' }}
        {...props}
    >
        <rect x="0" y="0" rx="0" ry="0" width="NaN" height="NaN" />
        <rect x="63" y="67" rx="3" ry="3" width="150" height="10" />
        <circle cx="927" cy="80" r="24" />
        <circle cx="329" cy="90" r="40" />
        <rect x="63" y="89" rx="3" ry="3" width="112" height="10" />
        <circle cx="469" cy="90" r="40" />
        <circle cx="609" cy="90" r="40" />
        <rect x="289" y="23" rx="3" ry="3" width="81" height="8" />
        <rect x="429" y="23" rx="3" ry="3" width="81" height="8" />
        <rect x="568" y="23" rx="3" ry="3" width="81" height="8" />
        <rect x="743" y="25" rx="3" ry="3" width="145" height="8" />
        <rect x="743" y="58" rx="3" ry="3" width="95" height="8" />
        <rect x="743" y="76" rx="3" ry="3" width="80" height="8" />
        <rect x="743" y="94" rx="3" ry="3" width="70" height="8" />
        <rect x="745" y="124" rx="14" ry="14" width="205" height="22" />
    </ContentLoader>
)

export default YourAbilitiesLoader