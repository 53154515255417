export const apiSplunk = async (userName, mail, error, errorBool, tipoVoz) => {
    // Logic App: setLogSplunk_App
    const postData = {
        userName: userName,
        mail: mail,
        error: 'N/A',
        currentDate: new Date(),
        type: tipoVoz,
        officeLocation: '',
        department: ''
    }

    if (errorBool) {
        postData.error = error;
    }

    var raw = JSON.stringify(
        {
            apiKey:"YB99J7mDI963K8O9zuSRy8YDRaH3PHq25L9hYvcu",
            data: postData,
            indice:"&REACT_APP_INDEX_SPLUNk&"
        }
    );

    await fetch("https://prod-56.eastus2.logic.azure.com/workflows/82cd620f325b4e40b77f2413aa0a8af6/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=ZRpUiIinE-GFncrlI4H0rR6E_qnzmwaJr1XDn3616gQ", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: raw
    })
};

export const sendEmail = async (nombreAsunto, targetName, targetEmail, descripcionVoz, competencias, footerText, subjectDescription,url,descripcion,btn,gift) => {
    // Logic App: sendEmailVozAvos_App/sendEmailVozAvosPRD_App
    const dataForEmail = JSON.stringify(
        {
            "competencias": competencias,
            "descripcionVoz": descripcionVoz,
            "footerText": footerText,
            "name": targetName,
            "subject": nombreAsunto,
            "subjectDescription": subjectDescription,
            "to": targetEmail, //"ejemplo@proteccion.com.co" Para ensayar dar y pedir voces a uno mismo - Varaible original -> //targetEmail
            "url": url,
            "feedback": descripcion,
            "btn": btn,
            "gift": gift,
        }
    );
    
    await fetch("https://prod-52.eastus2.logic.azure.com/workflows/61fbe25c36d0453e9b311ac134524cce/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=rrs_HxyWAsD9bEgoC4tjgoCARcEMnupEfDEmWiNZG1c", {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: dataForEmail
    })
};