import React from "react";
import classes from "./shared.module.css";
const FieldShowTitle = ({ title, iconSrc }) => {
  return (
    <div className={classes.fieldShowTitle}>
      <img width="30px" height="30px" src={iconSrc} alt={title} />
      <p>{title}</p>
    </div>
  );
};

export default FieldShowTitle;
