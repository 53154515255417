import React from "react";
import classes from "./shared.module.css";

import { useSkillList } from "./../../context/useSkillList";

import partyIconSrc from "./../../assets/party-popper_1f389.png";
import winkingIconSrc from "./../../assets/winking-face_1f609.png";
import bicepIconSrc from "./../../assets/flexed-biceps_1f4aa.png";
import AccordianBox from "./../../shared/ExpandableBox/ExpandableBox";

const MobileListContainer = (props) => {
  const { skillList, isCheckPillActive } = props;
  const { onAddHandler } = useSkillList();
  return (
    <div className={classes.mobileListContainer}>
      {skillList.map((obj) => {
        if (obj.type === "SPECIAL") {
          if (isCheckPillActive) {
            return (
              <AccordianBox key={obj.id} title={obj.title}>
                <ElementOptionDiv
                  id={obj.id}
                  category={obj.category}
                  clicked={onAddHandler}
                />
              </AccordianBox>
            );
          } else {
            return null;
          }
        } else {
          return (
            <AccordianBox key={obj.id} title={obj.title}>
              <ElementOptionDiv
                id={obj.id}
                category={obj.category}
                clicked={onAddHandler}
              />
            </AccordianBox>
          );
        }
      })}
    </div>
  );
};

const ElementOptionDiv = (props) => {
  const { category = "", clicked, id } = props;

  const first = () => {
    if (category === "excelente") {
      clicked(id, "N/a");
    } else {
      clicked(id, "excelente");
    }
  };
  const second = () => {
    if (category === "bueno") {
      clicked(id, "N/a");
    } else {
      clicked(id, "bueno");
    }
  };
  const third = () => {
    if (category === "puedes") {
      clicked(id, "N/a");
    } else {
      clicked(id, "puedes");
    }
  };
  return (
    <div className={classes.elementOptionDiv}>
      <div role="first" className={classes.elementOptItem} onClick={first}>
        <span
          style={{
            backgroundColor:
              category === "excelente" ? "#4132b4" : "transparent",
          }}
        >
          <img src={partyIconSrc} width="18px" alt="icon1" />
        </span>
        <p style={{ color: "#4132b4" }}>Excelente</p>
      </div>
      <div role="second" className={classes.elementOptItem} onClick={second}>
        <span
          style={{
            backgroundColor: category === "bueno" ? "#5D7FF1" : "transparent",
          }}
        >
          <img src={winkingIconSrc} width="18px" alt="icon2" />
        </span>
        <p style={{ color: "#5D7FF1" }}>Bueno</p>
      </div>
      <div role="third" className={classes.elementOptItem} onClick={third}>
        <span
          style={{
            backgroundColor: category === "puedes" ? "#FF808B" : "transparent",
          }}
        >
          <img src={bicepIconSrc} width="18px" alt="icon4" />
        </span>
        <p style={{ color: "#FF808B" }}>Puedes mejorar</p>
      </div>
    </div>
  );
};
export default MobileListContainer;
