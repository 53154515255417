import React, { useState, useEffect, useContext } from "react";
import classes from "./giveVoice.module.css";
import TabBarMisVoces from "./shared/TabBarMisVoces";
import TabContent from "./shared/TabContent";
import { vozavosContext } from "../vozavosContext";
import VocesTab from "./Tabs/MisVocesTab/VocesTab";
import { ProvideSkillList } from "./context/useSkillList";

const MyVoices = (props) => {
    const {
        state: { renderMyVoicesReceived }
    } = useContext(vozavosContext);
    const [whichTab, setWhichTab] = useState(1);

    useEffect(() => {
        if (renderMyVoicesReceived)
            setWhichTab(2);
    }, [renderMyVoicesReceived])

    // TAB CHANGE HANDLER
    const tabChangeHandler = (i) => {
        setWhichTab(i);
    };

    return (
        <ProvideSkillList>
            <div className={classes.giveVoiceWrapper} >
                <TabBarMisVoces activeTabIndex={whichTab} data-testid="provide" isVissibleTest={false} tabChangeHandler={tabChangeHandler} />
                <TabContent activeTabIndex={whichTab}>
                    <VocesTab tabIndex={whichTab} />
                </TabContent>
            </div>
        </ProvideSkillList>
    );
};
export default MyVoices;