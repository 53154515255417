import React from "react"
import ContentLoader from "react-content-loader"

const SubmitLoader = (props) => {
  const { tipoVoz } = props;

  return (
    <ContentLoader
      speed={2}
      width={500}
      height={230}
      viewBox="0 0 500 230"
      backgroundColor="#f3f3f3"
      foregroundColor="#d1d1d1"
      title={tipoVoz}
      style={{ width: '100%' }}
      {...props}
    >
      <rect x="165" y="35" rx="3" ry="3" width="300" height="9" />
      <rect x="165" y="60" rx="3" ry="3" width="137" height="9" />
      <circle cx="85" cy="75" r="55" />
      <text x="250" y="158" fill="black" fontSize="32" fontWeight="bold" textAnchor="middle">{tipoVoz}</text>
      <rect x="75" y="175" rx="4" ry="4" width="146" height="40" />
      <rect x="271" y="175" rx="4" ry="4" width="146" height="40" />
      <rect x="165" y="85" rx="3" ry="3" width="208" height="9" />
      <rect x="165" y="110" rx="3" ry="3" width="78" height="9" />
    </ContentLoader>
  )
}

export default SubmitLoader