import React from "react";
import classes from "./shared.module.css";

const CheckBoxPill = ({ isActive, checkPillHandler, title}) => {
  return (
    <div className={classes.checkBoxPillWrapper}>
      <input
        onChange={checkPillHandler}
        name="isPillActive"
        type="checkbox"
        checked={isActive === true}
        value={isActive}
      />
      <p>{title}</p>
    </div>
  );
};

export default CheckBoxPill;
