import React, { useState } from "react";
import classes from "./giveVoice.module.css";

import TabBarPedir from "./shared/TabBarPedir";
import TabContent from "./shared/TabContent";

import PedirVozTab from "./Tabs/PedirVozTab";
import { ProvideSkillList } from "./context/useSkillList";

const AskVoice = (props) => {
  const [whichTab, setWhichTab] = useState(2);

  // TAB CHANGE HANDLER
  // const tabChangeHandler = (i) => {
  //   setWhichTab(i);
  // };
  return (
    <ProvideSkillList>
      <div className={classes.giveVoiceWrapper}>
        {/* <TabBarPedir activeTabIndex={whichTab} tabChangeHandler={tabChangeHandler} /> */}
        <TabBarPedir activeTabIndex={whichTab} isVissibleTest={false}/>
        <TabContent activeTabIndex={whichTab}>
          <PedirVozTab tabIndex={whichTab} isCheckPillActiveDarVozT={false}/>
        </TabContent>
      </div>
    </ProvideSkillList>
  );
};
export default AskVoice;
