import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsconfig from "../../../aws-exports";
import { createVoz, updateVoz, deleteVoz } from "../../../graphql/mutations";
import { apiSplunk } from "../../../ApiLogicApp";
import { getVoz } from "../../../graphql/queries";
import { VoiceChat } from "@material-ui/icons";

Amplify.configure(awsconfig);

var vozsId

export const darVoz = async (competenciaSolicitada, competenciaExcelente, competenciaBueno, competenciaAdecuado, competenciaMejorar, descripcion, regalo, origen, nombre, destino, nombreDestino, id) => {
  
  const voz = {
    origen: origen, target: destino, comentario: descripcion, competenciaExcelente: competenciaExcelente,
    competenciaBueno: competenciaBueno, competenciaAdecuado: competenciaAdecuado, competenciaMejorar: competenciaMejorar, competenciaSolicitada: competenciaSolicitada, origenName: nombre, targetName: nombreDestino, anexos: regalo, drawerVisible: true, createdAt: new Date().toISOString()
  };

  try {
    await API.graphql(graphqlOperation(createVoz, { input: voz })).then((response) => response.data.createVoz.id);
    
     
  } catch (err) {
    await apiSplunk(nombre, origen.toLowerCase(), err, true, 'Error darVoz');
    return ('');
    
  }


  try {
    if (id) {
      await setDrawerVisible(id)
    }
  } catch (err) {
    await apiSplunk(nombre, origen.toLowerCase(), err, true, 'Error setDrawerVisible id');
    return ('');
  }
  await apiSplunk(nombre, origen.toLowerCase(), '', false, 'Voz dada');

  return (vozsId);
}

export const setDrawerVisible = async (id, userName, mail) => {
  try {
    await API.graphql(graphqlOperation(updateVoz, { input: { "id": id, "drawerVisible": false } }))
  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'Error setDrawerVisible');
    return (err);
  }
  return ('');
};

// const listaVozs = async (id) => {
   
//   const listavoz = {
//    id: id
//     };
//   try {
//      vozsId =await API.graphql(graphqlOperation(getVoz, { input: listavoz })).then((response) => response.data.getVoz.id);

//   } catch (err) {
//     await apiSplunk(id, err, true, 'Error darVoz');
//     return ('');
//   }
// };

export const borrarVoz = async (id, userName, mail) => {
  try {
    await API.graphql(graphqlOperation(deleteVoz, { input: { "id": id } }))
  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'Error borrarVoz');
    return (err);
  }
  return ('');
}

export const setConversation = async (id, conversacion, userName, mail) => {
  try {
   await API.graphql(graphqlOperation(updateVoz, { input: { "id": id, "conversacion": conversacion } }))
  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'Error setConversation');
    return (err);
  }
  return('');
}

export const pedirVoz = async (competencias, descripcion, origen, nombre, destino, nombreOrigen, fechaCreacion) => {
  let vozId;
  const voz = {
    origen: destino, target: origen, targetName: nombreOrigen, origenName: nombre, comentario: descripcion, competenciaSolicitada: competencias,
    competenciaExcelente: [],
    competenciaBueno: [],
    
    competenciaMejorar: [],
    drawerVisible: true,
    createdAt: fechaCreacion
  };
  try {
    vozId = await API.graphql(graphqlOperation(createVoz, { input: voz })).then((response) => response.data.createVoz.id);
  } catch (err) {
    await apiSplunk(nombre, destino.toLowerCase(), err, true, 'Error pedirVoz');
    return ('');
  }
  await apiSplunk(nombre, destino.toLowerCase(), '', false, 'Voz solicitada');
  return vozId;
}

