import React from "react"
import ContentLoader from "react-content-loader"

const MonthCompLoader = (props) => (
  <ContentLoader 
    speed={2}
    width={365}
    height={161}
    viewBox="0 0 365 161"
    backgroundColor="#f3f3f3"
    foregroundColor="#dcdbdb"
    title="Cargando resumen del mes..."
    style={{ width: '100%' }}
    {...props}
  >
    <rect x="51" y="24" rx="3" ry="3" width="88" height="7" /> 
    <rect x="186" y="73" rx="3" ry="3" width="141" height="7" /> 
    <circle cx="97" cy="97" r="49" /> 
    <circle cx="253" cy="37" r="16" /> 
    <rect x="206" y="93" rx="3" ry="3" width="100" height="7" />
  </ContentLoader>
)

export default MonthCompLoader;