import React from "react";
import { Alert } from "antd";
import "antd/dist/antd.css";
import "./alertcomp.css";
import cross from "../../home/assets/close-25px.svg";

const AlertComp = () => {
  return (
    <>
      <Alert 
        message="Tip del día"
        description={`Recuerda respirar y aclarar tus ideas antes de dar un feedback a un compañero.`}
        closable
        // onClose={onClose}
        src={cross}
      />
      {/* <div className="circleAlert"></div> */}
    </>
  );
};
export default AlertComp;
