import React from "react";
import FieldShowTitle from "./../FieldShowTitle";
import classes from "./../shared.module.css";
import msgSrc from "./../../../assets/memo_1f4dd.png";
import ballonSrc from "./../../../assets/balloon_1f388.png";
import DOMPurify from 'dompurify'

const Comentario = (props) => {
    const { palabra, comentario, anexo } = props;

    const sanitizedAnexo = () => {
        const regex = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/g);
        return {
            __html: DOMPurify.sanitize(
                anexo.replace(regex, url => {
                    return `<a href="${url}" rel="noopener noreferrer" class=${classes.pComentario}>${url}</a>`;
                })
            )
        }
    };

    return (
        <>
            {comentario ?
                <div className={classes.parentFlex}>
                    <FieldShowTitle
                        title={palabra === "dada a " ? "Comentario que has dejado a tu compañero" : "Comentario de tu compañero"}
                        iconSrc={msgSrc}
                    />
                    <div className={classes.contenedorComentario2}>
                        {comentario}
                    </div>
                </div> : ""
            }
            {anexo ?
                <div className={classes.parentFlex}>
                    <FieldShowTitle
                        title={palabra === "dada a " ? "Obsequio que has dejado a tu compañero" : "¡Sorpresa! Tu compañero te ha dejado un obsequio"}
                        iconSrc={ballonSrc}
                    />
                    <div className={classes.contenedorComentario2}>
                        <div className={classes.divComentario}>
                            {palabra !== "dada a " && <p>Abre el siguiente link para ver de qué se trata</p>}
                            <div dangerouslySetInnerHTML={sanitizedAnexo()} />
                        </div>
                    </div>
                </div > : ""
            }
        </>
    );
}

export default Comentario;