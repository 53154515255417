import React, { useContext, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import classes from "./shared.module.css";
import { darVoz, pedirVoz, borrarVoz } from "../../../giveVoiceComp/Tabs/api"
import { vocesTotalesByOrigen } from "../../../dasboard/api"
import { useSkillList } from "./../../context/useSkillList";
import { vozavosContext } from "../../../vozavosContext";
import iconHeaderModalOk from "../../../../components/home/assets/Grupo 1188.svg";
import iconHeaderModalError from "../../../../components/home/assets/ilustracion-errores.svg";
import "./SubmitButtonsWrapper.css";
import { sendEmail } from "../../../../ApiLogicApp";
import SubmitLoader from "./SubmitLoader";
import PeoplePicker from "../../../SearchBar/PeoplePicker";

const SubmitButtonsWrapper = (props) => {

  const { typeButton, setCheckPill, setCheckPillSkill, setComment, setTextGift, setCompanero,
    fnErrorCompetencias, fnErrorCompannero, fnErrorComentario, fnErrorRegalo, peopleSelected, comment, gift, id, scrollTo } = props;
  const { getArrayList, getArrayListDarVoz, getRegalo, resetTextArea, resetArrayList } = useSkillList();
  const { state: { mail, userName } } = useContext(vozavosContext);

  const [modal, setModal] = useState(false);
  const [modalEsencia, setModalEsencia] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const className = "modalcontent";
  let nombreAsunto = userName, targetName = "", descripcionVoz = "", competencias = "", footerText = "", descripcionAsunto = "", subjectDescription = "", url = "", descripcion = "", btn = "", giftText = "";

  const toggle = () => setModal(!modal);
  const toggleEsencia = () => setModalEsencia(!modalEsencia);
  const toggleError = () => setModalError(!modalError);

  const allToggle = () => {
    setModalEsencia(!modalEsencia);
    setModal(!modal);
  };
  const closeBtn = <button className="closeButton" data-testid="closeButton" onClick={toggle}></button>;
  let titleModal = "Enviar Voz";
  let messageModal1 = 'Vemos que has marcado algunas competencias de tu compañero como “Excelentes”.';
  let messageModal2 = '¿Quisieras de paso darle un reconocimiento en Nuestra Esencia?';
  let mensajeConfirmacion = 'Tu Voz ha sido enviada correctamente.';
  let loadingMessage = "Enviando tu Voz...";

  if (typeButton === 'pedirVoz') {
    titleModal = 'Pedir Voz';
    mensajeConfirmacion = peopleSelected.length === 1 ? 'Tu Voz ha sido solicitada correctamente.' : 'Tus Voces han sido solicitadas correctamente.';
    loadingMessage = peopleSelected.length === 1 ? "Solicitando tu Voz..." : "Solicitando tus Voces...";

  }
  const funcionBorradoVoz = async (idVoz, limite = 3) => {
    let resp = await borrarVoz(idVoz)
    limite--;
    if (resp !== '' && limite > 0) {
      return funcionBorradoVoz(idVoz, limite)
    }
    return "";
  }
  const splitName = async (nombre, tipo) => {
    let arrayNombre = nombre.replace(" de ", " ").replace(" del ", " ").replace(" los ", " ").replace(" De ", " ").replace(" Del ", " ").replace(" Los ", " ").split(" ");
    if (tipo === "saludo") {
      return arrayNombre[0];
    } else {
      if (arrayNombre.length === 4)
        return arrayNombre[0] + " " + arrayNombre[2];
      else return arrayNombre[0] + " " + arrayNombre[1];
    }
  };
  const enviarCorreo = async (descripcionAsuntoCorreo, nombreDestino) => {
    nombreAsunto = await splitName(nombreAsunto, "otro");
    targetName = await splitName(nombreDestino.text, "saludo");
    subjectDescription = nombreAsunto + descripcionAsuntoCorreo;
    await sendEmail(nombreAsunto, targetName, nombreDestino.tertiaryText.toLowerCase(), descripcionVoz, competencias, footerText, subjectDescription, url, descripcion, btn, giftText);
  };
  const voz = async () => {
    let error = false, res, errorGuardarVoz, filter, fechaCreacion, arrVocesEnviadas = [], arrFechas = [];
    setBtnDisabled(true);
    if (typeButton === 'darvoz') { //dar voz
      let arrayExcelente = getArrayList('excelente');
      let arrayBueno = getArrayList('bueno');
      let arrayAdecuado = getArrayList('adecuado');
      let arrayMejorar = getArrayList('mejorar');
      let arraySolicitado = [];
      let regalo = gift;
      descripcion = comment;

      descripcionVoz = arrayExcelente.length + arrayBueno.length + arrayMejorar.length + arrayAdecuado.length === 1 ? 'te ha <strong style="color: #4132b4">dado una Voz</strong> en la competencia:' : 'te ha <strong style="color: #4132b4">dado una Voz</strong> en las competencias:';
      descripcionAsunto = " te ha dado una Voz";
      arrayExcelente.forEach(competencia => competencias += '<li style="margin-top: 0px;margin-bottom: 0;margin-left: 0;">' + competencia + "</li>");
      arrayBueno.forEach(competencia => competencias += '<li style="margin-top: 0px;margin-bottom: 0;margin-left: 0;">' + competencia + "</li>");
      arrayMejorar.forEach(competencia => competencias += '<li style="margin-top: 0px;margin-bottom: 0;margin-left: 0;">' + competencia + "</li>");
      arrayAdecuado.forEach(competencia => competencias += '<li style="margin-top: 0px;margin-bottom: 0;margin-left: 0;">' + competencia + "</li>");
      footerText = 'Ingresa a <strong style="color: #4132b4">Voz a vos</strong>, visualiza en el <strong style="color: #4132b4">dashboard</strong> qué insumos tienes para tu plan de desarrollo y anímate a pedir más voces.';
     // url = `https://d4z3kyl03122t.cloudfront.net/myvoices/${res}`; //https://d4z3kyl03122t.cloudfront.net
      btn = "¡Ir a Voz a vos!"
      giftText = `<p style="text-align: left; padding-left: 20px; padding-right: 20px;">Tu compañero te ha dejado un regalo para tu desarrollo, ingresa al siguiente enlace para descubrirlo. <br><a href="${gift}">${gift}</a></p>`

      if (arrayExcelente.length === 0 && arrayBueno.length === 0 && arrayAdecuado.length === 0 && arrayMejorar.length === 0) {
        fnErrorCompetencias('Advertencia: Debes seleccionar mínimo una competencia.');
        scrollTo("errorCompetencias")
        error = true;
      }
      if (descripcion === '') {
        fnErrorComentario('Advertencia: Debes dejar un comentario a tu compañero.');
        scrollTo("errorComentario")
        error = true;
      }
      if (peopleSelected.length === 0) {
        fnErrorCompannero('Advertencia: Debes seleccionar un compañero.');
        scrollTo("errorCompannero");
        error = true;
      }
      if (peopleSelected.length > 0 && peopleSelected[0].tertiaryText === mail) {
        fnErrorCompannero('Advertencia: No es posible darte una Voz a ti mismo.');
        scrollTo("errorCompannero");
        error = true;
      }
      if (regalo.length > 0 && regalo.includes("http") === false) {
        fnErrorRegalo('Advertencia: Debes enviar un link de una página web.');
        scrollTo("errorRegalo");
        error = true;
      }
      if (!error) {
        let regaloDarVoz = getRegalo();
        setShowLoader(true);
        res = await darVoz(arraySolicitado, arrayExcelente, arrayBueno, arrayAdecuado, arrayMejorar, descripcion, regaloDarVoz, mail.toLowerCase(), userName, peopleSelected[0].tertiaryText.toLowerCase(), peopleSelected[0].text, id);
        url = `https://www.vozavos.com.co/myvoices/${res}`; //https://d4z3kyl03122t.cloudfront.net

        if (res != '') {
          await enviarCorreo(descripcionAsunto, peopleSelected[0]);
          setShowLoader(false);
          arrayExcelente.length > 0 ? toggleEsencia() : toggle();
          resetTextArea();
          resetArrayList('todos');
          setCheckPill(false);
          setCheckPillSkill(false);
          setComment('');
          setTextGift('');
          fnErrorComentario('');
          fnErrorCompetencias('')
          fnErrorCompannero('');
          setCompanero([]);
          setTimeout(() => {window.location.reload();}, 2000);
        }
        else {
          setModalError(!modalError);
        }
      }
    }
    else { //pedir voz
      let arrayDarVoz = getArrayListDarVoz();
      let descripcion = comment;
      descripcionVoz = arrayDarVoz.length === 1 ? 'te ha enviado una <strong style="color: #4132b4">solicitud de Voz</strong> en la competencia:' : 'te ha enviado una <strong style="color: #4132b4">solicitud de Voz</strong> en las competencias:';
      descripcionAsunto = " te ha solicitado una Voz";
      arrayDarVoz.forEach(competencia => competencias += '<li style="margin-top: 0px;margin-bottom: 0;margin-left: 0;">' + competencia + "</li>");
      footerText = 'Recuerda que al <strong style="color: #4132b4">darle tu Voz</strong> a un compañero estás contribuyendo significativamente en su <strong style="color: #4132b4">desarrollo personal y profesional.</strong>';
      btn = "¡Ir a la voz!";
      
      if (arrayDarVoz.length === 0) {
        fnErrorCompetencias('Advertencia: Debes seleccionar mínimo una competencia.');
        scrollTo("errorCompetencias");
        error = true;
      }
      if (peopleSelected.length === 0) {
        fnErrorCompannero('Advertencia: Debes seleccionar un compañero.');
        scrollTo("errorCompannero");
        error = true;
      }
      if (peopleSelected.length > 0 && peopleSelected[0].tertiaryText === mail) {
        fnErrorCompannero('Advertencia: No es posible darte una Voz a ti mismo.');
        scrollTo("errorCompannero");
        error = true;
      }
      if (!error) {
        setShowLoader(true);
        for (let person of peopleSelected) {
          fechaCreacion = new Date().toISOString();
          arrFechas.push(fechaCreacion);
          res = await pedirVoz(arrayDarVoz, descripcion, person.tertiaryText.toLowerCase(), userName, mail.toLowerCase(), person.text, fechaCreacion);
          url = `https://www.vozavos.com.co/givevoice/${res}`; //https://d4z3kyl03122t.cloudfront.net/givevoice
          if (res == '') {
            errorGuardarVoz = true;
            break;
          }
        }
        if (errorGuardarVoz) {
          filter = {
            origen: mail.toLowerCase(),
            sortDirection: "DESC",
            createdAt: { "between": [arrFechas[0], arrFechas.length > 1 ? arrFechas[arrFechas.length - 1] : arrFechas[0]] }
          };
          arrVocesEnviadas = await vocesTotalesByOrigen(filter, userName, mail);
          if (arrVocesEnviadas.length > 0) {
            for (let obj of arrVocesEnviadas) {
              let resp = await borrarVoz(obj.id);
              if (resp !== '') {
                await funcionBorradoVoz(obj.id);
              }
            }
          }
          setShowLoader(false);
          setModalError(!modalError);
        } else {
            setShowLoader(false);
            toggle();
            resetTextArea();
            resetArrayList('');
            setCheckPill(false);
            setCheckPillSkill(false);
            setComment(' ');
            fnErrorComentario('');
            fnErrorCompetencias('')
            fnErrorCompannero('');
            setCompanero([]);
            setTimeout(() => {window.location.reload();}, 2000);
        }

        if (!errorGuardarVoz) {
          for (let person of peopleSelected) {
            await enviarCorreo(descripcionAsunto, person);
          }
        }
      }
    }

    setBtnDisabled(false);
  };
  return (
    <div className={classes.submitButtonsWrapper}>
      <Button disabled={btnDisabled} className={classes.submitionBtn} data-testid="buttonModal" onClick={() => voz()}>{titleModal}</Button>

      <Modal isOpen={showLoader} contentClassName={className} centered={true} backdrop="static">
        <ModalHeader className="prueba modal-title-Cargando">
          <SubmitLoader tipoVoz={loadingMessage} />
        </ModalHeader>
      </Modal>
      <Modal isOpen={modalEsencia} toggle={toggleEsencia} contentClassName={className} centered={true} backdrop="static">
        <ModalHeader className="prueba modal-title-Excelente" toggle={toggleEsencia} close={closeBtn}>
          <div>
            <img className="modalHeaderIcon" src={iconHeaderModalOk} alt="Ícono modal ok" />
          </div>
          <div className="divHeaderExce">
            <div className="divBodyText">
              {messageModal1}
            </div>
            <div className="divBodyText">
              {messageModal2}
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="modalBody">
          <div>
            <button className={classes.cancelBtn} onClick={allToggle} data-testid="soloEnviar">No, solo enviar Voz</button>
            <a href="https://nuestraesencia.plandereconocimientos.com/pages/login" target="_blank" rel="noopener noreferrer"><button className={classes.submitionBtn} onClick={allToggle}>¡Si claro!</button></a>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal} toggle={toggle} contentClassName="modalcontentNoExcelente" centered={true} backdrop="static">
        <ModalHeader className="prueba" toggle={toggle} close={closeBtn}>
          <div>
            <img className="modalHeaderIcon" src={iconHeaderModalOk} alt="Ícono modal ok" />
          </div>
          <div className="divHeaderImage">
            <div className="divBodyTextNoExce">{mensajeConfirmacion}</div>
          </div>
        </ModalHeader>
        <ModalBody className="modalBody">
          <button className={classes.submitionBtn} onClick={toggle}>Cerrar</button>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalError} toggle={toggleError} contentClassName="modalcontentNoExcelente" centered={true} backdrop="static">
        <ModalHeader className="prueba" toggle={toggleError} close={closeBtn}>
          <div>
            <img className="modalHeaderIcon" src={iconHeaderModalError} alt="Ícono modal error" />
          </div>
          <div className="divHeaderImageError">
            <div className="divBodyText">No se pudo guardar tu Voz, por favor intenta de nuevo.</div>
          </div>
        </ModalHeader>
        <ModalBody className="modalBody">
          <button className={classes.submitionBtn} onClick={toggleError}>Cerrar</button>
        </ModalBody>
      </Modal>
      {/* <button className={classes.cancelBtn}>Cancelar</button> */}

    </div>
  );
};
export default SubmitButtonsWrapper;