import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1000}
    height={350}
    viewBox="0 0 1000 350"
    backgroundColor="#f3f3f3"
    foregroundColor="#dcdbdb"
    title="Cargando tus voces..."
    style={{ width: '100%' }}
    {...props}
  >
    <rect x="748" y="298" rx="4" ry="4" width="160" height="40" />
    <rect x="109" y="308" rx="3" ry="3" width="88" height="6" />
    <rect x="109" y="326" rx="3" ry="3" width="180" height="6" />
    <circle cx="52" cy="321" r="27" />
    <rect x="474" y="308" rx="3" ry="3" width="52" height="6" />
    <text x="515" y="200" fill="black" fontSize="32" fontWeight="bold" textAnchor="middle">Cargando tus voces...</text>
    <rect x="474" y="326" rx="3" ry="3" width="88" height="6" />
    <rect x="748" y="166" rx="4" ry="4" width="160" height="40" />
    <rect x="109" y="180" rx="3" ry="3" width="88" height="6" />
    <rect x="109" y="198" rx="3" ry="3" width="180" height="6" />
    <circle cx="52" cy="193" r="27" />
    <rect x="109" y="49" rx="3" ry="3" width="88" height="6" />
    <rect x="109" y="67" rx="3" ry="3" width="180" height="6" />
    <circle cx="52" cy="62" r="27" />
    <rect x="474" y="49" rx="3" ry="3" width="52" height="6" />
    <rect x="474" y="67" rx="3" ry="3" width="88" height="6" />
    <rect x="748" y="36" rx="4" ry="4" width="160" height="40" />
  </ContentLoader>
)

export default MyLoader
