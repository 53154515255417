import React, { useState, useContext, useEffect } from "react";
import { Drawer } from "antd";
import { NavLink } from "react-router-dom";
import menu from "./../../home/assets/menu.png";
import drwerBtnLine from "./../../home/assets/drawer-line-big@2x.png";
import { vocesTotalesByTarget } from "../api";
import { setDrawerVisible } from "../../giveVoiceComp/Tabs/api";
import { vozavosContext } from "../../vozavosContext";
import { animateScroll as scroll } from 'react-scroll';
import { getUserPhoto } from "../../../assets/api";
import "./drawer.css";

const scrollType = {
  duration: 10,
  smooth: true,
};

const DrawerComp = (props) => {
  const {
    state: { client, userName, mail, renderGiveVoice, renderMyVoices, renderMyVoicesReceived }, //mail
    actions: { setRenderGiveVoice, setRenderMyVoices, setRenderMyVoicesReceived },
  } = useContext(vozavosContext);
  const { setShowDotIcon } = props;
  const [visible, setVisible] = useState(false);
  const [listVoces, setListVoces] = useState([]);
  const [showLid, setShowllid] = useState(false);

  useEffect(() => {
    async function loadVocesSinleer() {
      var vocesList = await generateVocesList();
      setListVoces(vocesList);
    }
    loadVocesSinleer();
  }, [mail])

  const showDrawer = () => {
    setVisible(true);
    setShowllid(true);
  };
  const onClose = () => {
    setVisible(false);
    setShowllid(false);
  };
  const getDifferenceTime = async (fecha) => {
    // asignar el valor de las unidades en milisegundos
    var msecPerMinute = 1000 * 60;
    var msecPerHour = msecPerMinute * 60;
    var msecPerDay = msecPerHour * 24;

    // asignar la fecha en milisegundos 2020-07-25T00:49:57.423Z
    var dateItem = new Date(fecha);
    var currentDate = new Date();
    var dateItemMsec = dateItem.getTime();
    var currentDateMsec = currentDate.getTime();

    // Obtener la diferencia en milisegundos
    var interval = currentDateMsec - dateItemMsec;

    var days = Math.floor(interval / msecPerDay);

    interval = interval - (days * msecPerDay);
    var hours = Math.floor(interval / msecPerHour);

    interval = interval - (hours * msecPerHour);
    var minutes = Math.floor(interval / msecPerMinute);

    interval = interval - (minutes * msecPerMinute);
    var seconds = Math.floor(interval / 1000);

    if (days > 0)
      return `Hace ${days + (days === 1 ? ' día' : ' días')}`;
    if (hours > 0)
      return `Hace ${hours + (hours === 1 ? ' hora' : ' horas')}`;
    if (minutes > 0)
      return `Hace ${minutes + (minutes === 1 ? ' minuto' : ' minutos')}`;
    if (seconds > 0)
      return `Hace ${seconds + (seconds === 1 ? ' segundo' : ' segundos')}`;
  }

  const generateVocesList = async () => {
    let vc, urlPhoto, vocesList;
    let filterTarget = {
      target: mail.toLowerCase(),
      filter: {
        "drawerVisible": { "eq": true },
        "competenciaSolicitada": { "ne": null }
      }
    };

    if (mail.length > 0) {
      vc = await vocesTotalesByTarget(filterTarget, userName, mail);
      vocesList = [];
      for (const item of vc) {
        urlPhoto = await getUserPhoto(item.origen === mail.toLowerCase() ? item.target : item.origen,
          item.origen === mail.toLowerCase() ? item.targetName : item.origenName, client);

        vocesList.push({
          nameVoz: item.origen === mail.toLowerCase() ? item.targetName : item.origenName,
          createdAt: item.createdAt,
          id: item.id,
          url: urlPhoto,
          vozDesc: item.target === mail.toLowerCase() && item.competenciaSolicitada.length > 0 ? 'quiere recibir tu Voz!' : 'te ha dado una Voz!',
          tipoVoz: item.target === mail.toLowerCase() && item.competenciaSolicitada.length > 0 ? 'pedir' : 'dar',
          time: await getDifferenceTime(item.createdAt)
        });
      }
      if (vocesList.length > 0) setShowDotIcon(true);
      const sortedList = [...vocesList].sort((a, b) => (a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0));
      return sortedList;
    }
  }

  const misVocesTab = async (id) => {
    setRenderMyVoices(!renderMyVoices);
    scroll.scrollToTop(scrollType);
    if (!renderMyVoicesReceived)
      setRenderMyVoicesReceived(true);
    await setDrawerVisible(id, userName, mail);
  };

  const renderTableData = () => {
    if (typeof listVoces !== 'undefined') {
      return listVoces.map((voz, index) => {
        const { id, nameVoz, time, url, vozDesc, tipoVoz } = voz
        return (
          <>
            <tr id={id.toString()} key={index}>
              <td className="filaImpar" rowSpan="2">
                <div className="contentImg">
                  <img id='photoTarget' mode='fit' src={url} alt="" />
                </div>
              </td>
              <td className="filaImparcontent">
                {tipoVoz === 'pedir' ?
                  <NavLink to={`/givevoice/${id}`} onClick={() => { setRenderGiveVoice(!renderGiveVoice); scroll.scrollToTop(scrollType); }}>
                    <div className="content">¡{nameVoz} {vozDesc}</div>
                  </NavLink>
                  :
                  <NavLink to={`/voice/${id}`} onClick={() => { misVocesTab(id) }}>
                    <div className="content">¡{nameVoz} {vozDesc}</div>
                  </NavLink>
                }
              </td>
            </tr>
            <tr>
              <td className="filaImpar">
                <span className="timeduration">{time}</span>
              </td>
            </tr>
          </>
        )
      })
    }
  }

  return (
    <>
      < div className="sideDrawer" >
        <div
          className={showLid ? "drwerDivBtnMove " : "drwerDivBtn"}
          onClick={showDrawer} data-testid="showDrawer"
        >
          <img className="drwerBtnLineStyle" src={drwerBtnLine} alt="" />
          <img className="drwerBtnLineStyle1" src={drwerBtnLine} alt="" />
        </div>
        <div className="drwerDivBtn1" onClick={showDrawer}>
          <img className="" src={menu} alt="" />
        </div>

        <div className="opnnotific" data-testid="opnnotific" onClick={showDrawer}></div>
        <Drawer
          title="Notificaciones"
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
          mask={true}
        >
          <table className="cssTable" data-testid="cssTable" border="0px">
            <tbody >
              {renderTableData()}
            </tbody>
          </table>
        </Drawer>
      </div >
    </>
  );
};
export default DrawerComp;
