import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ProfileBoxMisVoces from "../profileBoxMisVoces";
import Competencias from "./Competencias";
import Comentario from "./Comentario";
import MyVoicesLoader from "./MyVoicesLoader";
import SubmitLoader from "../SubmitLoader";
import commentSrc from "./../../../assets/insert_comment-24px.svg";
import iconHeaderModalOk from "../../../../../components/home/assets/Grupo 1188.svg";
import iconHeaderModalError from "../../../../../components/home/assets/ilustracion-errores.svg";
import classes from "./../shared.module.css";
import { sendEmail } from "../../../../../ApiLogicApp";
import { detalleVoz } from "../../../../dasboard/api";
import { setConversation } from "../../../Tabs/api";
 
const VoiceTableInfo = (props) => {
    let { categoria, palabra, idRegistro, nombrePersona, urlFoto, toggle, cargandoTest, mail, userName, origenVoz, time, conversacion, isVozDrawer } = props;
    const [str, setStr] = useState("");
    const [visible, setVisible] = useState(false);
    const [comentario, setComentario] = useState('');
    const [anexo, setAnexo] = useState('');
    const [arrayExcelente, setArrayExcelente] = useState('');
    const [arrayBueno, setArrayBueno] = useState('');
    const [arrayAdecuado, setArrayAdecuado] = useState('');
    const [arrayMejorar, setArrayMejorar] = useState('');
    const [cargando, setCargando] = useState(cargandoTest);
    const [talk, setTalk] = useState(conversacion);
    const [errorComentario, setErrorComentario] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [nameVoz, setNameVoz] = useState("");
    const [emailTo, setEmailTo] = useState("");

    

    conversacion = conversacion === null ? [] : conversacion;

    const toggleModal = () => setModal(!modal);
    const toggleError = () => setModalError(!modalError);
    const closeBtn = <button className="closeButton" data-testid="closeButton" onClick={toggleModal}></button>;

    useEffect(() => {
        async function loadVoces() { try{
            let voz = await generateVoiceDetail();
            setNameVoz(userName !== origenVoz ? voz.origenName : voz.targetName);
            setComentario(voz.comentario);
            setAnexo(voz.anexos);
            setArrayExcelente(voz.competenciaExcelente);
            setArrayBueno(voz.competenciaBueno);
            setArrayAdecuado(voz.competenciaAdecuado);
            setArrayMejorar(voz.competenciaMejorar);
            setTalk(voz.conversacion);
            setEmailTo(userName !== origenVoz ? voz.email : voz.emailTarget);
            setCargando(c => !c);
            
        } catch (error) {
            console.error(`Error cargando los detalles de voz: ${error}`);}
        }
        loadVoces();
    }, [idRegistro])

    const generateVoiceDetail = async () => {
        var filter = {
            "id": idRegistro
        };
        var vc = await detalleVoz(filter);
        return vc;
    }

    const splitName = async (nombre, tipo) => {
        let arrayNombre = nombre.replace(" de ", " ").replace(" del ", " ").replace(" los ", " ").replace(" De ", " ").replace(" Del ", " ").replace(" Los ", " ").split(" ");
        if (tipo === "saludo") {
            return arrayNombre[0];
        } else {
            if (arrayNombre.length === 4)
                return arrayNombre[0] + " " + arrayNombre[2];
            else return arrayNombre[0] + " " + arrayNombre[1];
        }
    };

    const enviarCorreo = async (descripcionAsuntoCorreo) => {
        let nombreAsunto = userName, targetName = "",
            descripcionVoz = 'ha hecho un comentario sobre la <strong style="color: #4132b4">Voz enviada o recibida </strong> el día ' + time.toLocaleDateString('es-CO') + '.',
            footerText = 'Ingresa a <strong style="color: #4132b4">Voz a Vos</strong> y observa el comentario realizado. Recuerda consultar en el <strong style="color: #4132b4">dashboard</strong> que insumos tienes para tu plan de desarrollo e incluso si necesitas pedir más voces.',
            subjectDescription = "",
            btn = "¡Ir a Voz a vos!",
            url = "https://www.vozavos.com.co/myvoices"; //https://d4z3kyl03122t.cloudfront.net/myvoices

        nombreAsunto = await splitName(nombreAsunto, "otro");
        targetName = await splitName(nameVoz, "saludo");
        subjectDescription = nombreAsunto + descripcionAsuntoCorreo;
        await sendEmail(nombreAsunto, targetName, emailTo,  descripcionVoz, "", footerText, subjectDescription, url, "", btn,  "");
    };

    const sendResponse = async () => {
        let conversacion, error = false, descripcionAsunto = " ha dado respuesta a tu Voz";
        const dateCurrent = new Date();

        if (str === '') {
            setErrorComentario('Advertencia: Debes dejar un comentario válido a tu compañero.');
            error = true;
        }

        if (visible) {
            if (!error) {
                setShowLoader(true);
                if (talk === null) {
                    conversacion = [{
                        name: userName,
                        creacion: dateCurrent.toISOString(),
                        mensaje: str,
                    }]
                } else {
                    conversacion = [...talk, {
                        name: userName,
                        creacion: dateCurrent.toISOString(),
                        mensaje: str,
                    }]
                }
                setTalk(conversacion);
                setVisible(false);
                let res = await setConversation(idRegistro, conversacion, userName, mail)
                if (res === '') {
                    await enviarCorreo(descripcionAsunto);
                    setShowLoader(false);
                    toggleModal();
                } else {
                    setModalError(!modalError);
                }
            }
        } else {
            toggle();
        }
    }

    function setTextAns(value) {
        setStr(value);
        if (value !== '') {
            setErrorComentario('');
        }
    }

    const showCommentBox = () => {
        if (visible) {
            setVisible(false);
        } else {
            setVisible(true);
        }
    };

    const enableComment = () => {
        if (talk === null || talk.length === 0) {
            if (userName !== origenVoz || conversacion.length === 0) {
                return (showBtnComment());
            }
        } else if (talk.length === 1) {
            if (userName !== nombrePersona && userName === origenVoz && isVozDrawer) {
                return (showBtnComment());
            }
        }
    }

    const RenderConversation = () => {
        return (
            <>
                {talk !== null &&
                    talk.map((message, index) => {
                        return (
                            <>
                                <div className={classes.fieldShowTitle}>
                                    <p>{message.name === userName ? "Tu comentario" : "Respuesta a tu Voz"}</p>
                                </div>
                                <div className={classes.contenedorComentario2}>{talk[index].mensaje}</div>
                            </>
                        )
                    })
                }
            </>
        )
    };

    const showBtnComment = () => {
        if (talk === null || talk.length < 2) {
            return (
                <div className={classes.divFlex2}>
                    <div style={{ display: "flex", cursor: "pointer" }} onClick={showCommentBox} data-testid="btnLeaveComment">
                        <img src={commentSrc} alt='' />
                        <p className={classes.tituloComentario}>Dejar un comentario</p>
                    </div>
                </div>
            )
        }
    }

    if (cargando) {
        return <MyVoicesLoader />
    } else {
        return (
            <>
                <ProfileBoxMisVoces palabra={palabra} nameVoz={nombrePersona} urlFoto={urlFoto} />
                <Competencias categoria={categoria} palabra={palabra} arrayExcelente={arrayExcelente} arrayBueno={arrayBueno} arrayAdecuado={arrayAdecuado} arrayMejorar={arrayMejorar} />
                <Comentario palabra={palabra} comentario={comentario} anexo={anexo} />
                <RenderConversation />
                {enableComment()}
                {visible &&
                    <>
                        <textarea
                            className={`${classes.inputSendFields} styleScroll ${str.length === 300 && classes.commentTxtAreaFull
                                }`}
                            type="textarea"
                            placeholder="Escribe aquí el comentario que quieres hacerle a tu compañero."
                            rows="3"
                            // data-isFull="false"
                            value={str}
                            onChange={({ target: { value } }) => setTextAns(value)}
                            style={{ color: "black" }}
                            maxLength={300}
                        />
                        <span style={{
                            float: "left",
                            textalign: "left",
                            font: "12px/19px CabinRegular",
                            letterspacing: "0px",
                            color: "#A51A1A",
                            opacity: "1",
                        }}
                        >{errorComentario}</span>
                        <span style={{ float: "right", letterSpacing: "2px", color: "#888888", fontWeight: "500", }}>{str.length}/300</span>
                    </>
                }
                <div className={classes.divFlex2} style={{ marginTop: "3%" }}>
                    <Button className="pedirVozBtnFila" data-testid="toggle" onClick={() => sendResponse()}>
                        {visible ? "Enviar" : "Cerrar"}
                    </Button>
                </div>

                <Modal isOpen={showLoader} contentClassName="modalcontent" centered={true} backdrop="static">
                    <ModalHeader className="prueba modal-title-Cargando">
                        <SubmitLoader tipoVoz="Enviando tu respuesta" />
                    </ModalHeader>
                </Modal>

                <Modal isOpen={modal} toggle={toggleModal} contentClassName="modalcontentNoExcelente" centered={true} backdrop="static">
                    <ModalHeader className="prueba" toggle={toggleModal} close={closeBtn}>
                        <div>
                            <img className="modalHeaderIcon" src={iconHeaderModalOk} alt="Ícono modal ok" />
                        </div>
                        <div className="divHeaderImage">
                            <div className="divBodyTextNoExce">Tu respuesta ha sido enviada correctamente.</div>
                        </div>
                    </ModalHeader>
                    <ModalBody className="modalBody">
                        <button className={classes.submitionBtn} onClick={toggleModal}>Cerrar</button>
                    </ModalBody>
                </Modal>

                <Modal isOpen={modalError} toggle={toggleError} contentClassName="modalcontentNoExcelente" centered={true} backdrop="static">
                    <ModalHeader className="prueba" toggle={toggleError} close={closeBtn}>
                        <div>
                            <img className="modalHeaderIcon" src={iconHeaderModalError} alt="Ícono modal error" />
                        </div>
                        <div className="divHeaderImageError">
                            <div className="divBodyText">No se pudo enviar tu respuesta, por favor intenta de nuevo.</div>
                        </div>
                    </ModalHeader>
                    <ModalBody className="modalBody">
                        <button className={classes.submitionBtn} onClick={toggleError}>Cerrar</button>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

export default VoiceTableInfo;
