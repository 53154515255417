import React from "react";
import { DateRangePicker } from 'rsuite';
import { subDays } from 'date-fns';
import 'rsuite/dist/styles/rsuite-default.css';

const { afterToday } = DateRangePicker;

const Calendar = (props) => {
    const { setDataRange, dataRange } = props;

    const handleDateChange = (date) => {
        if (date.length === 2) {
            date[1] = `${JSON.stringify(date[1]).slice(1,11)}T23:59:59.625Z`
        }
        setDataRange(date);
    };

    return (
        <div className="field calendar">
            <DateRangePicker
                showOneCalendar
                placement="autoVerticalEnd"
                format="DD-MM-YYYY"
                placeholder='Filtrar por día, semana o mes'
                cleanable={true}
                defaultValue={dataRange}
                // appearance='subtle'
                isoWeek={true}
                size='lg'
                locale={{
                    sunday: 'Do',
                    monday: 'Lu',
                    tuesday: 'Ma',
                    wednesday: 'Mi',
                    thursday: 'Ju',
                    friday: 'Vi',
                    saturday: 'Sa',
                    ok: 'OK'
                }}
                ranges={[
                    {
                        label: 'Últimos 7 días',
                        value: [subDays(new Date(), 6), new Date()]
                    }, {
                        label: 'Último mes',
                        value: [subDays(new Date(), 29), new Date()]
                    }
                ]}
                disabledDate={afterToday()}
                onChange={handleDateChange}
            />
        </div>
    );
};

export default Calendar;