import avatar from "./avatar-360x360.svg";

export const generateAvatar = async (name) => {
  var initials = name.split(' ').map(function (str) { return str ? str[0].toUpperCase() : ""; }).join('');
  var canvas = document.createElement('canvas');
  var radius = 30;
  var margin = 5;
  canvas.width = radius * 2 + margin * 2;
  canvas.height = radius * 2 + margin * 2;

  // Get the drawing context
  var ctx = canvas.getContext('2d');
  ctx.beginPath();
  ctx.arc(radius + margin, radius + margin, radius, 0, 2 * Math.PI, false);
  ctx.closePath();
  ctx.fillStyle = '#00AEC7'
  ctx.fill();
  ctx.fillStyle = "white";
  ctx.font = "bold 25px Arial";
  ctx.textAlign = 'center';
  ctx.fillText(initials.substring(0, 2), radius + 5, radius * 4 / 3 + margin);
  return canvas.toDataURL();
};

export const getUserPhoto = async (user, name, client) => {
  return new Promise((resolve, reject) => {
    client
      .api(`users/${user}/photo/$value`)
      .version("beta")
      .header("Cache-Control", "no-cache")
      .responseType("blob")
      .get((err, res) => {
        if (!err) {
          return resolve(window.URL.createObjectURL(res));
        }
      }).catch(function (error) {
        return resolve(generateAvatar(name));
      });
  })
};

export const getPeople = (client, limit, callback) => {
  client
    .api("/me/people")
    .version("beta")
    .filter(`personType eq 'Person'`)
    .select("displayName,givenName,surname,emailAddresses,userPrincipalName,title")
    .top(limit)
    .get((err, res) => {
      if (err) {
        // console.log(err.code + " - " + err.message);
        //handleError(err);
      }
      callback(err, res ? res.value : []);
    });
};
export const getUserPhotoHome = async (client) => {
  return new Promise((resolve, reject) => {
    client
      .api(`/me/photo/$value`)
      .version("beta")
      .header("Cache-Control", "no-cache")
      .responseType("blob")
      .get((err, res) => {
        if (!err)
          return resolve(window.URL.createObjectURL(res));
      }).catch(function (error) {
        return resolve(avatar);
      });
  })
}
