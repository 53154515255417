import React, { useContext } from "react";
import "./../home/home.css";
import { NavLink } from "react-router-dom";
import logo from "./assets/logo.svg";
import { vozavosContext } from "../vozavosContext";

const HomeComponent = () => {
  const {
    state: { renderDashboard },
    actions: { setRenderDashboard },
  } = useContext(vozavosContext);

  return (
    <div className="dashboardnavbar">
      <div className="logoDiv">
        <NavLink to="/dashboard" onClick={() => { setRenderDashboard(!renderDashboard) }}>
          <img className="logoStyl" data-testid ="logoStyl" src={logo} alt="logo" />
        </NavLink>
      </div>

    </div>
  );
};
export default HomeComponent;
