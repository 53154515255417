import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import faceGray from "./../../home/assets/Grupo 1151.svg";
import party from "./../../home/assets/party-popper_1f389.png";
import sad from "./../../home/assets/white-frowning-face_2639.png";
import bicep from "./../../home/assets/flexed-biceps_1f4aa.png";
import upDownLines from "./../../home/assets/Grupo 1139_1.svg";
import { vozavosContext } from "../../vozavosContext";
import { animateScroll as scroll } from 'react-scroll';

const scrollType = {
    duration: 10,
    smooth: true,
};

const HistoricComp = (props) => {
    const {
        state: { renderAskVoice },
        actions: { setRenderAskVoice },
    } = useContext(vozavosContext);

    const { totalCompetenciaCategoriaLen } = props;
    const [vocesTotales, setVocesTotales] = useState(0);
    const [descVocesTotales, setDescVocesTotales] = useState('');
    const [emoji, setEmoji] = useState('');

    useEffect(() => {
        async function getVocesTotales() {
            let numVocesTotales = totalCompetenciaCategoriaLen.excelente + totalCompetenciaCategoriaLen.bueno + totalCompetenciaCategoriaLen.mejorar + totalCompetenciaCategoriaLen.adecuado;

            if (numVocesTotales >= 1 && numVocesTotales <= 49) {
                setDescVocesTotales('Ups ¡Puedes pedir más voces!');
                setEmoji(sad);
            }
            else if (numVocesTotales <= 144) {
                setDescVocesTotales('¡Puedes hacerlo mejor!');
                setEmoji(bicep);
            }
            else {
                setDescVocesTotales('Wow ¡Eres bastante popular!');
                setEmoji(party);
            }

            setVocesTotales(numVocesTotales);
        }
        getVocesTotales();
    }, [totalCompetenciaCategoriaLen, vocesTotales])

    if (vocesTotales === 0) {
        return (
            <>
                <div className="row">
                    <div className="grayFace">
                        <img src={faceGray} alt="" />
                    </div>
                    <div className="DivTextDesc">Aún no tienes voces recibidas</div>
                </div>
                <div className="row fila">
                    <NavLink to="/askvoice">
                        <button className="pedirVozBtnFila" data-testid="pedirVozBtnFila" onClick={() => { setRenderAskVoice(!renderAskVoice); scroll.scrollToTop(scrollType); }}>
                            Pedir Voz
                        </button>
                    </NavLink>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="totalVoicesReceived">Total de competencias recibidas</div>
                <div className="totalVoicesCount">{vocesTotales}</div>
                <div className="wowDivText">{descVocesTotales}</div>
                <div className="giftImgDiv">
                    <img src={emoji} alt="" width="40px" height="40px" />
                </div>
                <div className="upDownLinesDiv">
                    <img className="updownLineimg" src={upDownLines} alt="" />
                </div>
            </>
        );
    }
}

export default HistoricComp;