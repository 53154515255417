import React from "react"
import ContentLoader from "react-content-loader"

const HistoricCompLoader = (props) => (
    <ContentLoader
        speed={2}
        width={250}
        height={150}
        viewBox="0 0 250 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        title="Cargando el total de voces recibidas..."
        style={{ width: '100%' }}
        {...props}
    >
        <rect x="63" y="13" rx="3" ry="3" width="130" height="8" />
        <rect x="80" y="45" rx="3" ry="3" width="85" height="8" />
        <circle cx="40" cy="63" r="24" />
        <rect x="80" y="60" rx="3" ry="3" width="65" height="8" />
        <rect x="80" y="75" rx="3" ry="3" width="52" height="8" />
        <circle cx="210" cy="66" r="24" />
        <rect x="45" y="104" rx="0" ry="0" width="158" height="27" />
    </ContentLoader>
)

export default HistoricCompLoader
