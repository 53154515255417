import React, { createContext, useContext, useState, useEffect } from "react";
import { obtenerCompetencias } from "../../dasboard/api";
import { vozavosContext } from "../../vozavosContext";

const skillContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useSkillList().
export function ProvideSkillList({ children, id }) {
  const crudSkill = useProviderSkillList(id);
  return (
    <skillContext.Provider value={crudSkill}>{children}</skillContext.Provider>
  );
}

// Hook for child components to get the skillList object ...
// ... and re-render when it changes.
export const useSkillList = () => {
  return useContext(skillContext);
};

// Provider hook that creates auth object and handles state
function useProviderSkillList(id) {

  const {
    state: { mail, userName }
  } = useContext(vozavosContext);
  const [skillList, setSkillList] = useState([]);
  const [arrayExcelente, setArrayExcelente] = useState([]);
  const [arrayBueno, setArrayBueno] = useState([]);
  const [arrayAdecuado, setArrayAdecuado] = useState([]);
  const [arrayMejorar, setArrayMejorar] = useState([]);
  const [arrayDarVoz, setArrayDarVoz] = useState([]);
  const [regalo, setVarRegalo] = useState('');
  const [checkBoxArrayAbility, setCheckBoxArrayAbility] = useState([]);
  const [checkBoxArrayComer, setCheckBoxArrayComer] = useState([]);
  const [checkBoxArrayServi, setCheckBoxArrayServi] = useState([]);

  useEffect(() => {
    async function getSkillList() {
      let competencias = await obtenerCompetencias(id, userName, mail)
      let chkBoxArrayAbility = competencias.filter(skill => skill.type === "ACTIVE-SKILL").map(({ title }) => title);
      let chkBoxArrayComer = competencias.filter(skill => skill.type === "SPECIAL").map(({ title }) => title);
      let chkBoxArrayServi = competencias.filter(skill => skill.type === "SERVICIO").map(({ title }) => title);
      setSkillList(competencias);
      setCheckBoxArrayAbility(chkBoxArrayAbility);
      setCheckBoxArrayComer(chkBoxArrayComer);
      setCheckBoxArrayServi(chkBoxArrayServi);
    }
    getSkillList();
  }, [id])


  const onAddHandler = (id, cat) => {
    // three skill set
    // 1) excelente 2) bueno 3) puedes

    let updatedList = skillList.filter((skill) => {
      if (skill.id === id) {
        skill.category = cat;
      }
      return skill;
    });

    setSkillList(updatedList);
  };
  const deleteSkillHandler = (id, itemTitle, category) => {
    let arrE, arrB, arrM, arrAD, arrDV;
    if (category === 'excelente') {
      arrE = arrayExcelente.filter(item => item !== itemTitle);
      setArrayExcelente(arrE);
    }
    if (category === 'bueno') {
      arrB = arrayBueno.filter(item => item !== itemTitle);
      setArrayBueno(arrB);
    }
    if (category === 'adecuado') {
      arrAD = arrayAdecuado.filter(item => item !== itemTitle);
      setArrayAdecuado(arrAD);
    }
    if (category === 'puedes') {
      arrM = arrayMejorar.filter(item => item !== itemTitle);
      setArrayMejorar(arrM);
    }
    if (category === 'competencias') {
      arrDV = arrayDarVoz.filter(item => item !== itemTitle);
      setArrayDarVoz(arrDV);
    }
    let updatedList = skillList.filter((skill) => {

      if (skill.id === id) {
        skill.category = skill.type === "SPECIAL" || skill.type === "ACTIVE-SKILL" ? "CHECKBOX" : "N/A";
      }
      return skill;
    });
    setSkillList(updatedList);
  };

  // OnDragStart you need to pass an skill-id
  const onDragStart = (ev, id, itemTitle, itemType) => {
    ev.dataTransfer.setData("id", id);
    ev.dataTransfer.setData("itemTitle", itemTitle);
  };

  const onDragOver = (ev) => {
    ev.preventDefault();
  };

  const onDrop = (ev, cat) => {

    let id = ev.dataTransfer.getData("id");
    let itemTitle = ev.dataTransfer.getData("itemTitle");
    var arrE, arrB, arrM, arrDV, arrAD;

    arrE = arrayExcelente;  arrB = arrayBueno; arrAD = arrayAdecuado; arrM = arrayMejorar;  arrDV = arrayDarVoz;

    if(itemTitle === 'Satisfacción' || itemTitle === 'Calidad' || itemTitle === 'Productividad' ||itemTitle === 'Gestión de conocimiento'){
      if(arrE.includes('Hace agendamiento') || arrE.includes('Prospecta') || arrE.includes('Visita al cliente') || arrE.includes('Prepara la visita') || arrAD.includes('Hace agendamiento') || arrAD.includes('Prospecta') || arrAD.includes('Visita al cliente') || arrAD.includes('Prepara la visita') || arrB.includes('Hace agendamiento') || arrB.includes('Prospecta') || arrB.includes('Visita al cliente') || arrB.includes('Prepara la visita') ||arrM.includes('Hace agendamiento') || arrM.includes('Prospecta') || arrM.includes('Visita al cliente') || arrM.includes('Prepara la visita') || arrDV.includes('Hace agendamiento') || arrDV.includes('Prospecta') || arrDV.includes('Visita al cliente') || arrDV.includes('Prepara la visita')){return;}}
    if(itemTitle === 'Hace agendamiento' || itemTitle === 'Prospecta' || itemTitle === 'Visita al cliente' ||itemTitle === 'Prepara la visita'){
      if(arrE.includes('Satisfacción') || arrE.includes('Calidad') || arrE.includes('Productividad') || arrE.includes('Gestión de conocimiento') || arrAD.includes('Satisfacción') || arrAD.includes('Calidad') || arrAD.includes('Productividad') || arrAD.includes('Gestión de conocimiento') || arrB.includes('Satisfacción') || arrB.includes('Calidad') || arrB.includes('Productividad') || arrB.includes('Gestión de conocimiento') || arrM.includes('Satisfacción') || arrM.includes('Calidad') || arrM.includes('Productividad') || arrM.includes('Gestión de conocimiento') || arrDV.includes('Satisfacción') || arrDV.includes('Calidad') || arrDV.includes('Productividad') || arrDV.includes('Gestión de conocimiento')){
          return;}}

    if (cat === 'excelente') {
      arrE.push(itemTitle);
      arrB = arrayBueno.filter(item => item !== itemTitle);
      arrAD = arrayAdecuado.filter(item => item !== itemTitle);
      arrM = arrayMejorar.filter(item => item !== itemTitle);
      setArrayExcelente(arrE);
      setArrayBueno(arrB);
      setArrayAdecuado(arrAD);
      setArrayMejorar(arrM);
    }
    if (cat === 'bueno') {
      arrB.push(itemTitle);
      arrE = arrayExcelente.filter(item => item !== itemTitle);
      arrAD = arrayAdecuado.filter(item => item !== itemTitle);
      arrM = arrayMejorar.filter(item => item !== itemTitle);
      setArrayExcelente(arrE);
      setArrayBueno(arrB);
      setArrayAdecuado(arrAD);
      setArrayMejorar(arrM);
    }
    if (cat === 'adecuado') {
      arrAD.push(itemTitle);
      arrE = arrayExcelente.filter(item => item !== itemTitle);
      arrM = arrayMejorar.filter(item => item !== itemTitle);
      arrB = arrayBueno.filter(item => item !== itemTitle);
      setArrayExcelente(arrE);
      setArrayBueno(arrB);
      setArrayAdecuado(arrAD);
      setArrayMejorar(arrM);
    }

    if (cat === 'puedes') {
      arrM.push(itemTitle);
      arrE = arrayExcelente.filter(item => item !== itemTitle);
      arrAD = arrayAdecuado.filter(item => item !== itemTitle);
      arrB = arrayBueno.filter(item => item !== itemTitle);
      setArrayExcelente(arrE);
      setArrayBueno(arrB);
      setArrayAdecuado(arrAD);
      setArrayMejorar(arrM);
    }
    if (cat === 'competencias') {
      arrDV.push(itemTitle);
      setArrayDarVoz(arrDV);
    }
    let updatedList = skillList.filter((skill) => {
      if (skill.id === id) {
        skill.category = cat;
      }
      return skill;
    });

    setSkillList(updatedList);
  };

  const resetSpecialSkills = () => {
    let updatedList = resetArray(skillList, "SPECIAL", true);
    setSkillList(updatedList);
    invokeResetArray("SPECIAL", false, checkBoxArrayComer);
  };

  const resetSkills = () => {
    let updatedList = resetArray(skillList, "ACTIVE-SKILL", true);
    setSkillList(updatedList);
    invokeResetArray("ACTIVE-SKILL", false, checkBoxArrayAbility);
  };

  const resetServiSkills = () => {
    let updatedList = resetArray(skillList, "SERVICIO", true);
    setSkillList(updatedList);
    invokeResetArray("SERVICIO", false, checkBoxArrayServi);
  };

  const invokeResetArray = (tipo, boolTypeArray, chkArray) => {
    let arrE, arrB, arrM, arrAD, arrDV;
    arrE = resetArray(arrayExcelente, tipo, boolTypeArray, chkArray);
    setArrayExcelente(arrE);
    arrB = resetArray(arrayBueno, tipo, boolTypeArray, chkArray);
    setArrayBueno(arrB);
    arrAD = resetArray(arrayAdecuado, tipo, boolTypeArray, chkArray);
    setArrayAdecuado(arrAD);
    arrM = resetArray(arrayMejorar, tipo, boolTypeArray, chkArray);
    setArrayMejorar(arrM);
    arrDV = resetArray(arrayDarVoz, tipo, boolTypeArray, chkArray);
    setArrayDarVoz(arrDV);
  };

  const resetArray = (array, skillType, isSkillList, chkArray = []) => {
    let filteredArray;
    if (isSkillList) {
      filteredArray = array.filter((skill) => {
        if (skill.type === skillType) {
          skill.category = "CHECKBOX";
        }
        return skill;
      });
    } else {
      filteredArray = array.filter(competencia => !chkArray.includes(competencia));
    }
    return filteredArray;
  };

  const getArrayList = (arrayName) => {
    if (arrayName === 'excelente')
      return arrayExcelente;
    if (arrayName === 'bueno')
      return arrayBueno;
    if (arrayName === 'adecuado')
      return arrayAdecuado;
    if (arrayName === 'mejorar')
      return arrayMejorar
  };
  const getArrayListDarVoz = () => {
    return arrayDarVoz;
  };
  const getArrayLength = () => {
    if (arrayExcelente.length === 0 &&
      arrayBueno.length === 0 &&
      arrayAdecuado.length === 0 &&
      arrayMejorar.length === 0)
      return true;
    return false;
  };
  const getArrayLengthDarVoz = () => {
    if (arrayDarVoz.length === 0)
      return true;
    return false;
  };

  const setRegalo = (regaloDarVoz) => {
    setVarRegalo(regaloDarVoz);
  };

  const getRegalo = () => {
    return regalo;
  };

  const resetArrayList = (arrayName) => {
    if (arrayName === 'todos') {
      setArrayExcelente([]);
      setArrayBueno([]);
      setArrayAdecuado([]);
      setArrayMejorar([]);
    }
    if (arrayName === '')
      setArrayDarVoz([]);
  }
  const resetTextArea = () => {
    let updatedList = skillList.filter((skill) => {
      //if (skill.id === id) {
      skill.category = skill.type === "SPECIAL" || skill.type === "ACTIVE-SKILL" ? "CHECKBOX" : "N/A";
      //}
      return skill;
    });
    setSkillList(updatedList);
  };
  // Return the user object and auth methods
  return {
    skillList,
    setSkillList,
    resetTextArea,
    onAddHandler,
    deleteSkillHandler,
    onDragStart,
    onDragOver,
    onDrop,
    resetSpecialSkills,
    resetSkills,
    resetServiSkills,
    getArrayList,
    getArrayListDarVoz,
    setRegalo,
    getRegalo,
    getArrayLength,
    getArrayLengthDarVoz,
    resetArrayList,
  };
}
