import React, { useState, useEffect, useContext } from "react";
import cross from "./../../home/assets/close-24px.svg";
import emogi from "./../../home/assets/winking-face_1f609.png";
import faceBlue from "./../../home/assets/Grupo 1157.svg";
import { NavLink } from "react-router-dom";
import { vocesRecibidas } from "../api";
import { vozavosContext } from "../../vozavosContext";
import circleBlue from "./../../home/assets/circle-base-big-1.svg";
import { animateScroll as scroll } from 'react-scroll';
import MonthCompLoader from "./Skeletons/MonthCompLoader";

const scrollType = {
    duration: 10,
    smooth: true,
};

const MonthComp = (props) => {
    const {
        state: { userName, mail, renderAskVoice },
        actions: { setRenderAskVoice },
    } = useContext(vozavosContext);

    const { cargandoTest, consultaPU = false, filtroPU = 0 } = props
    const [alert, setalert] = useState(false);
    const [descVoces, setDescVoces] = useState('');
    const [voces, setVoces] = useState(0);
    const [mes, setMes] = useState('');
    const [cargando, setCargando] = useState(cargandoTest);

    useEffect(() => {
        async function getVozMes() {
            const dateCurrent = new Date();
            let apiFilter, numVoces;
            if (consultaPU) {
                apiFilter = filtroPU;
            } else {
                apiFilter = {
                    target: mail.toLowerCase(),
                    createdAt: { "ge": dateCurrent.toISOString().substr(0, 7) },
                    filter: {
                        "competenciaSolicitada": { "size": { "eq": 0 } }
                    }
                }
            }

            const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
            setMes(monthNames[dateCurrent.getMonth()] + ' ' + dateCurrent.getFullYear());
            if (mail.length > 0) {
                numVoces = await vocesRecibidas(apiFilter, userName, mail);
                if (numVoces >= 1 && numVoces <= 3) setDescVoces('¡Pide más voces!');
                else if (numVoces <= 8) setDescVoces('Y... ¿Si pides más voces?');
                else setDescVoces('Muy bien ¿Qué tal una Voz más?');
                setVoces(numVoces);
                setCargando(false);
            }
        }
        getVozMes();
    }, [mail])

    const AlrtDiv = () => {
        setalert(true);
    };

    if (cargando) {
        return (
            <div className={"emojiDivVoz"}>
                <MonthCompLoader/>
            </div>
        )
    } else {
        if (voces === 0) {
            return (
                <div className={alert ? "hidee" : "emojiDivVoz"}>
                    <div className="cerrar">
                        <img className="crossSymbol" data-testid="botonCerrar" id="botonCerrar" onClick={AlrtDiv} src={cross} alt="" />
                    </div>
                    <div className="flexFaceMonth">
                        <div className="april2020"><p className="descripcion">{mes}</p></div>
                        <div className="blueCirclediv">
                            <img className="circleBlue" src={faceBlue} alt="" />
                        </div>
                    </div>
                    <div className="flexVoces">
                        <div className="noVoces">Aún no tienes voces este mes</div>
                        <NavLink to="/askvoice">
                            <button data-testid="botonEnviarVoz" id="botonEnviarVoz" className="pedirVozBtn" onClick={() => { setRenderAskVoice(!renderAskVoice); scroll.scrollToTop(scrollType); }}>
                                Pedir Voz
                                </button>
                        </NavLink>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={alert ? "hidee" : "emojiDivVoz"}>
                    <div className="cerrar">
                        <img className="crossSymbol" onClick={AlrtDiv} src={cross} alt="" />
                    </div>
                    <div className="flexFaceMonth">
                        <div className="april2020"><p className="descripcion">{mes}</p></div>
                        <div className="blueCirclediv">
                            <img className="circleBlue" src={circleBlue} alt="" />
                            <div className="tweleveNumber"><p className="descripcion">{voces}</p></div>
                            <div className="voiceReceved"><p className="descripcion">voces recibidas</p></div>
                        </div>
                    </div>
                    <div className="flexVocesMensaje">
                        <div className="emojiStyl">
                            <img style={{ width: "37px" }} className="emojisize" src={emogi} alt="" />
                        </div>
                        <div className="yourDoingGreat"><p className="descripcion">{descVoces}</p></div>
                    </div>
                </div>
            );
        }
    }

}
export default MonthComp;