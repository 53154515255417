import React, { useState, useContext, useEffect } from "react";
import { NormalPeoplePicker } from "office-ui-fabric-react/lib/Pickers";
import { MessageBar } from "office-ui-fabric-react/lib/MessageBar";
// import { MessageBar, MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import { vozavosContext } from "../../components/vozavosContext";
import { getPeople } from "../../assets/api";
import "./peoplepicker.css"

const PeoplePicker = (props) => {
  const { setPeopleSelected, fnErrorCompannero, originName, originMail, limit } = props;
  const {
    state: { client },
  } = useContext(vozavosContext);
  const [selectedPeople, setSelectedPeople] = useState([])
  const [isLoadingPics, setIsLoadingPics] = useState(true)
  const [result, setResult] = useState()
  const [selectedPerson, setSelectedPerson] = useState([])
  let _peopleList = null;
  let _searchResults = [];

  useEffect(() => {
    async function loadPP() {
      if (originName !== undefined && originName.length > 0 && selectedPerson.length === 0) {
        let arr = await loadDefaultPerson();
        setSelectedPerson([...selectedPerson, arr]);
      }
    }
    loadPP();
  }, [originName, originMail]);

  const loadDefaultPerson = async () => {
    let mail = originMail;
    let arrayNames = originName.split(' ');
    let nombres = '';
    let apellidos = '';
    if (arrayNames.length >= 4) {
      nombres = arrayNames[0] + " " + arrayNames[1];
      apellidos = arrayNames[2] + " " + arrayNames[3];
    }
    else {
      nombres = arrayNames[0];
      apellidos = arrayNames[1] + " " + arrayNames[2];
    }
    var persona = {
      "key": 1,
      "imageUrl": "",
      "imageInitials": !!nombres && !!apellidos
        ? nombres.substring(0, 1) + apellidos.substring(0, 1)
        : (nombres + ' ' + apellidos).substring(0, 1),
      "text": nombres + ' ' + apellidos,
      "secondaryText": mail,
      "tertiaryText": mail,
      "optionalText": "",
      "isValid": true,
      "initialsColor": "#00AEC7",
      "props": { id: "1", userPrincipalName: mail },
      "presence": 0
    }
    setPeopleSelected([persona]);

    return persona;
  }

  // Map user properties to persona properties.
  const mapUsersToPersonas = (users, useMailProp) => {
    var i = 1;

    return users.map((p) => {
      // The email property is returned differently from the /users and /people endpoints.
      let email = p.userPrincipalName;//useMailProp ? p.mail : p.emailAddresses[0].address;
      var persona = {
        "key": `${p.title || p.jobTitle} ${email || p.userPrincipalName} ${i++}`,
        "imageUrl": "",
        "imageInitials": !!p.givenName && !!p.surname
          ? p.givenName.substring(0, 1) + p.surname.substring(0, 1)
          : p.displayName.substring(0, 1),
        "text": p.displayName,
        "secondaryText": p.title || p.jobTitle,
        "tertiaryText": email || p.userPrincipalName,
        "optionalText": '',
        "isValid": true,
        "initialsColor": "#00AEC7",
        "props": { id: p.id, userPrincipalName: p.userPrincipalName },
        "presence": 0
      }
      return persona;
    });
  }

  // Gets the profile photo for each user.
  const getPics = (personas) => {
    // Make suggestions available before retrieving profile pics.
    /*
        this.sdkHelper.getProfilePics(personas, (err) => {
          this.setState({
            isLoadingPics: false,
          });
        });
    */
  }

  const onEmptyInputFocus = async (items) => {
    setIsLoadingPics(false);
    return new Promise((resolve, reject) =>
      getPeople(client, 5, (err, people) => {
        if (!err) {
          _peopleList = mapUsersToPersonas(people, false);
          getPics(_peopleList);
          resolve(_peopleList);
        } else {
          showError(err);
        }
      })
    ).then((value) =>
      value
        .concat(_searchResults)
        .filter((item) => !listContainsPersona(item, items))
    );
  }

  const listContainsPersona = (persona, items) => {
    if (!items || !items.length || items.length === 0) {
      return false;
    }
    return (
      items.filter((item) => item.text === persona.text).length > 0
    );
  }

  const onGetMoreResults = (searchText, items) => {
    setIsLoadingPics(false);
    return new Promise((resolve) => {
      searchForPeople(5,
        searchText.toLowerCase(),
        (err, people) => {
          if (!err) {
            _searchResults = mapUsersToPersonas(people, true);
            getPics(_searchResults);
            resolve(_searchResults);
          }
        }
      );
    }).then((value) =>
      value
        .filter((item) => !listContainsPersona(item, items))
    );
  }

  const searchForPeople = (limit, searchText, callback) => {
    client
      .api("/users")
      .version("beta")
      .filter(`startswith(displayName,'${searchText}') and jobTitle ge '!'`)
      .select("displayName,givenName,surname,mail,userPrincipalName,id,jobTitle")
      .top(limit)
      .get((err, res) => {
        if (err) {
          handleError(err);
        }
        callback(err, res ? res.value : []);

      });
  }
  const onSelectionChanged = (items) => {
    if (items.length > 0) {
      //let mailSelected = items[0].secondaryText;
      setPeopleSelected(items);
      /*let nameSelected = items[0].primaryText;
      this.props.setNameMailSelected(nameSelected);      */
      fnErrorCompannero('');
    }
    else {
      setPeopleSelected([]);
      setResult(null)
      setSelectedPeople([...selectedPeople, items])
    }
  }

  const showError = (err) => {
    setResult(`Error ${err.statusCode}: ${err.code} - ${err.message}`);
    // console.log(`Error ${err.statusCode}: ${err.code} - ${err.message}`)
    // this.setState({
    //   result: {
    //     type: MessageBarType.error,
    //     text: `Error ${err.statusCode}: ${err.code} - ${err.message}`,
    //   },
    // });
  }


  const handleError = (err) => {
    // console.log(err.code + " - " + err.message);

    // This sample just redirects to the login function when the token is expired.
    // Production apps should implement more robust token management.
    // if (err.statusCode === 401 && err.message === "Access token has expired.") {
    //   this.props.login();
    // }
  }

  return (
    <div>
      <div className="FindaPartnerInput">
        <NormalPeoplePicker
          id="search"
          name="search"
          onResolveSuggestions={onGetMoreResults}
          onEmptyInputFocus={onEmptyInputFocus}
          itemLimit={limit}
          removeButtonAriaLabel={'Eliminar'}
          selectedItems={originName.length !== 0 ? selectedPerson : undefined}
          pickerSuggestionsProps={{
            suggestionsHeaderText: "Personas sugeridas",
            noResultsFoundText: "No se encontraron resultados",
            //searchForMoreText: "Buscar",
            loadingText: "Cargando...",
            isLoading: isLoadingPics,
          }}
          inputProps={{ placeholder: "Buscar un compañero   🔍" }}
          getTextFromItem={(persona) => persona.text}
          onChange={onSelectionChanged}
          // onGetMoreResults={onGetMoreResults}
          className="ms-PeoplePicker"
          key="normal-people-picker"
        />

        {result && (
          <MessageBar messageBarType={result.type}>
            {result.text}
          </MessageBar>
        )}
      </div>
    </div>
  );
}



export default PeoplePicker;