import React from "react";
import classes from "./shared.module.css";

import { ReactComponent as CloseIcon } from "./../../assets/close-24px.svg";
import { useSkillList } from "./../../context/useSkillList";

const ListItem = (props) => {
  const { itemTitle, id, category } = props;
  const { deleteSkillHandler, onDragStart } = useSkillList();

  return (
    <li
      data-testid="answer"
      id="answer"
      className={classes.listItem}
      onDragStart={(e) => onDragStart(e, id,itemTitle)}
      draggable
    >
      <span>{itemTitle}</span>
      <CloseIcon
        data-testid="deleteSkillHandler"
        className={classes.closeIcon}
        onClick={() => deleteSkillHandler(id,itemTitle,category)}
      />
    </li>
  );
};

export default ListItem;
