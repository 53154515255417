/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVoz = /* GraphQL */ `
  mutation CreateVoz(
    $input: CreateVozInput!
    $condition: ModelVozConditionInput
  ) {
    createVoz(input: $input, condition: $condition) {
      id
      origen
      target
      origenName
      targetName
      comentario
      competenciaExcelente
      competenciaBueno
      competenciaAdecuado
      competenciaMejorar
      competenciaSolicitada
      anexos
      createdAt
      drawerVisible
      conversacion {
        name
        creacion
        mensaje
      }
      updatedAt
    }
  }
`;
export const updateVoz = /* GraphQL */ `
  mutation UpdateVoz(
    $input: UpdateVozInput!
    $condition: ModelVozConditionInput
  ) {
    updateVoz(input: $input, condition: $condition) {
      id
      origen
      target
      origenName
      targetName
      comentario
      competenciaExcelente
      competenciaBueno
      competenciaAdecuado
      competenciaMejorar
      competenciaSolicitada
      anexos
      createdAt
      drawerVisible
      conversacion {
        name
        creacion
        mensaje
      }
      updatedAt
    }
  }
`;
export const deleteVoz = /* GraphQL */ `
  mutation DeleteVoz(
    $input: DeleteVozInput!
    $condition: ModelVozConditionInput
  ) {
    deleteVoz(input: $input, condition: $condition) {
      id
      origen
      target
      origenName
      targetName
      comentario
      competenciaExcelente
      competenciaBueno
      competenciaAdecuado
      competenciaMejorar
      competenciaSolicitada
      anexos
      createdAt
      drawerVisible
      conversacion {
        name
        creacion
        mensaje
      }
      updatedAt
    }
  }
`;
export const createCompetencia = /* GraphQL */ `
  mutation CreateCompetencia(
    $input: CreateCompetenciaInput!
    $condition: ModelCompetenciaConditionInput
  ) {
    createCompetencia(input: $input, condition: $condition) {
      id
      nombre
      descripcion
      tipo
      categoria
      sinonimos
      createdAt
      updatedAt
    }
  }
`;
export const updateCompetencia = /* GraphQL */ `
  mutation UpdateCompetencia(
    $input: UpdateCompetenciaInput!
    $condition: ModelCompetenciaConditionInput
  ) {
    updateCompetencia(input: $input, condition: $condition) {
      id
      nombre
      descripcion
      tipo
      categoria
      sinonimos
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompetencia = /* GraphQL */ `
  mutation DeleteCompetencia(
    $input: DeleteCompetenciaInput!
    $condition: ModelCompetenciaConditionInput
  ) {
    deleteCompetencia(input: $input, condition: $condition) {
      id
      nombre
      descripcion
      tipo
      categoria
      sinonimos
      createdAt
      updatedAt
    }
  }
`;
export const createTip = /* GraphQL */ `
  mutation CreateTip(
    $input: CreateTipInput!
    $condition: ModelTipConditionInput
  ) {
    createTip(input: $input, condition: $condition) {
      id
      nombreTip
      nombreColorTip
      descripcionTip
      descripcionColorTip
      imagen
      fondo
      createdAt
      updatedAt
    }
  }
`;
export const updateTip = /* GraphQL */ `
  mutation UpdateTip(
    $input: UpdateTipInput!
    $condition: ModelTipConditionInput
  ) {
    updateTip(input: $input, condition: $condition) {
      id
      nombreTip
      nombreColorTip
      descripcionTip
      descripcionColorTip
      imagen
      fondo
      createdAt
      updatedAt
    }
  }
`;
export const deleteTip = /* GraphQL */ `
  mutation DeleteTip(
    $input: DeleteTipInput!
    $condition: ModelTipConditionInput
  ) {
    deleteTip(input: $input, condition: $condition) {
      id
      nombreTip
      nombreColorTip
      descripcionTip
      descripcionColorTip
      imagen
      fondo
      createdAt
      updatedAt
    }
  }
`;
