/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVoz = /* GraphQL */ `
  query GetVoz($id: ID!) {
    getVoz(id: $id) {
      id
      origen
      target
      origenName
      targetName
      comentario
      competenciaExcelente
      competenciaBueno
      competenciaAdecuado
      competenciaMejorar
      competenciaSolicitada
      anexos
      createdAt
      drawerVisible
      conversacion {
        name
        creacion
        mensaje
      }
      updatedAt
    }
  }
`;
export const listVozs = /* GraphQL */ `
  query ListVozs(
    $filter: ModelVozFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVozs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        origen
        target
        origenName
        targetName
        comentario
        competenciaExcelente
        competenciaBueno
        competenciaAdecuado
        competenciaMejorar
        competenciaSolicitada
        anexos
        createdAt
        drawerVisible
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompetencia = /* GraphQL */ `
  query GetCompetencia($id: ID!) {
    getCompetencia(id: $id) {
      id
      nombre
      descripcion
      tipo
      categoria
      sinonimos
      createdAt
      updatedAt
    }
  }
`;
export const listCompetencias = /* GraphQL */ `
  query ListCompetencias(
    $filter: ModelCompetenciaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetencias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
        descripcion
        tipo
        categoria
        sinonimos
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTip = /* GraphQL */ `
  query GetTip($id: ID!) {
    getTip(id: $id) {
      id
      nombreTip
      nombreColorTip
      descripcionTip
      descripcionColorTip
      imagen
      fondo
      createdAt
      updatedAt
    }
  }
`;
export const listTips = /* GraphQL */ `
  query ListTips(
    $filter: ModelTipFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTips(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombreTip
        nombreColorTip
        descripcionTip
        descripcionColorTip
        imagen
        fondo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listVocesByOrigen = /* GraphQL */ `
  query ListVocesByOrigen(
    $origen: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVozFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVocesByOrigen(
      origen: $origen
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        origen
        target
        origenName
        targetName
        comentario
        competenciaExcelente
        competenciaBueno
        competenciaAdecuado
        competenciaMejorar
        competenciaSolicitada
        anexos
        createdAt
        drawerVisible
        updatedAt
      }
      nextToken
    }
  }
`;
export const listVocesByTarget = /* GraphQL */ `
  query ListVocesByTarget(
    $target: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVozFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVocesByTarget(
      target: $target
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        origen
        target
        origenName
        targetName
        comentario
        competenciaExcelente
        competenciaBueno
        competenciaAdecuado
        competenciaMejorar
        competenciaSolicitada
        anexos
        createdAt
        drawerVisible
        updatedAt
      }
      nextToken
    }
  }
`;
