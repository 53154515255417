import React, { useState, useEffect } from "react";
import faceBluei from "./../../home/assets/Grupo 1148.svg";
import circleBlueD from "./../../home/assets/circulo.svg";
import { competenciasFuertes } from "../api";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import Zoom from '@material-ui/core/Zoom';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    // backgroundColor: theme.palette.common.white,
    backgroundColor: 'rgba(222, 227, 255, 1)',
    color: 'rgba(41, 39, 48, 1)',
    boxShadow: theme.shadows[1],
    borderRadius: 10,
    fontSize: 12,
    padding: 12,
  },
}))(Tooltip);

const StrongestSkills = (props) => {
  const [descM1, setDescM1] = useState('');
  const [descM2, setDescM2] = useState('');
  const [descM3, setDescM3] = useState('');
  const [valueM1, setValueM1] = useState('');
  const [valueM2, setValueM2] = useState('');
  const [valueM3, setValueM3] = useState('');
  var desc = "Estos números representan el peso en porcentaje de tus tres competencias más fuertes.";

  useEffect(() => {
    async function getCompetenciasFuertes() {
      const resCompetenciasFuertes = await competenciasFuertes(props.competencias, props.abilitiesArray);
      setDescM1(resCompetenciasFuertes.m1.key);
      setDescM2(resCompetenciasFuertes.m2.key);
      setDescM3(resCompetenciasFuertes.m3.key);
      setValueM1(resCompetenciasFuertes.m1.value);
      setValueM2(resCompetenciasFuertes.m2.value);
      setValueM3(resCompetenciasFuertes.m3.value);
    }

    getCompetenciasFuertes();
  }, [props.competencias])

  if (valueM1 === 0 || valueM2 === 0 || valueM3 === 0) {
    return (
      <>
        <div className="yourStrongestSkillText">Tus competencias más fuertes</div>
        <div className="flexCompetenciaVacio">
          <div className="contenedorCompetenciaVacio">
            <div className="txtCompetenciaVacio"><p className="descripcion derecho">Apenas recibas más voces, empezarás a ver cuáles son tus mejores competencias</p></div>
          </div>
          <div className="divimgCompetenciaVacio">
            <img className="imgCompetenciaVacio" src={faceBluei} alt="" />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <LightTooltip title={desc} arrow TransitionComponent={Zoom}>
          <div className="yourStrongestSkillText">Tus competencias más fuertes</div>
        </LightTooltip>
        <div className="flexCompetencias">
          <div className="contenedoresCompetencias">
            <div className="courageText"><p className="descripcion">{descM1}</p></div>
            <div className="firstcircleBluesecond">
              <img className="circleBlueSecond" src={circleBlueD} alt="" />
              <div className="blueCirclecount"><p className="valorCompetencia">{valueM1}</p></div>
            </div>
          </div>
          <div className="contenedoresCompetencias">
            <div className="ColaborationText"><p className="descripcion">{descM2}</p></div>
            <div className="SecondcircleDiv">
              <img className="secondCircle" src={circleBlueD} alt="" />
              <div className="secondCicleCount"><p className="valorCompetencia">{valueM2}</p></div>
            </div>
          </div>
          <div className="contenedoresCompetencias">
            <div className="inovationText"><p className="descripcion">{descM3}</p></div>
            <div className="thirdcircleDiv">
              <img className="thirdcircle" src={circleBlueD} alt="" />
              <div className="thirdcircleCount"><p className="valorCompetencia">{valueM3}</p></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default StrongestSkills;
