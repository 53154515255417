import React, { useState, useContext, useEffect } from "react";
import "./dashboard.css";
import AlertComponent from "./alert/AlertComp";
import DrawerComp from "./drawer/DrawerComp";
import NewGraph from "./graph/newgraph/newgraph";
// import { ReactComponent as Search } from "./../home/assets/search-24px.svg";
// import MaterialUIPicker from "./calender";
// import arrowdropdown from "./../home/assets/keyboard_arrow_right-24px.svg";
// import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { Login } from "../../screens/Login/SignInCustom";
import { vozavosContext } from "../vozavosContext";
import { competenciasGraph } from "./api";
import HistoricComp from "./stateCards/HistoricComp";
import StrongestSkills from "./stateCards/StrongestSkills";
import YourSkills from "./stateCards/YourSkills";
import YourAbilities from "./stateCards/YourAbilities";
import "./../home/home.css";
import MonthComp from './stateCards/MonthComp';
import Elipsedot from "./../home/assets/Elipse 56.svg";
import notification_circled from "./../home/assets/notification-circled.svg";
import Calendar from '../giveVoiceComp/Tabs/shared/Calendar';
import { obtenerCompetencias } from "./api";
import StrongestSkillsLoader from "./stateCards/Skeletons/StrongestSkillsLoader";
import HistoricCompLoader from "./stateCards/Skeletons/HistoricCompLoader";
import YourAbilitiesLoader from "./stateCards/Skeletons/YourAbilitiesLoader";

const DashBoardComp = () => {
  const {
    state: { loginSession, mail, userName },
  } = useContext(vozavosContext);

  const [totalCompetenciaCategoria, setTotalCompetenciaCategoria] = useState([]);
  const [totalCompetenciaCategoriaLen, setTotalCompetenciaCategoriaLen] = useState({});
  const [totalCompetencia, setTotalCompetencia] = useState({});
  const [cargando, setCargando] = useState(true);
  const [showDotIcon, setShowDotIcon] = useState(false);
  const [dataRange, setDataRange] = useState([]);
  const [abilitiesArray, setAbilitiesArray] = useState([{ "state": "Reuniones efectivas", "maleyoung": 0, "malemiddle": 0, "malehalf": 0, "maleolder": 0 }]);

  useEffect(() => {
    async function getVocesRecibidas() {
      const dateCurrent = new Date();
      let filter, resCompetenciasGraph, competencias, skillArray, filteredArray;
      if (dataRange.length === 0) {
        filter = {
          target: mail.toLowerCase(),
          createdAt: { "ge": (parseInt(dateCurrent.toISOString().substr(0, 4)) - 3).toString() }
        }
      } else {
        filter = {
          target: mail.toLowerCase(),
          createdAt: { "between": [dataRange[0], dataRange[1]] }
        }
      }

      if (mail.length > 0) {
        competencias = await obtenerCompetencias(false, userName, mail);
        skillArray = competencias.filter(skill => skill.type === "ACTIVE-SKILL").map(({ title }) => title);
        resCompetenciasGraph = await competenciasGraph(filter, userName, mail, skillArray);
        filteredArray = resCompetenciasGraph.totalCompetenciaCategoria.filter(({ state }) => skillArray.includes(state));
        if (filteredArray.length > 0)
          setAbilitiesArray(filteredArray);
        setTotalCompetencia(resCompetenciasGraph);
        setTotalCompetenciaCategoria(resCompetenciasGraph.totalCompetenciaCategoria.filter(({ state }) => !skillArray.includes(state)));
        setTotalCompetenciaCategoriaLen(resCompetenciasGraph.totalCompetenciasLongitud);
        setCargando(false);
      }
    }
    getVocesRecibidas();
  }, [mail, showDotIcon, dataRange])

  if (!loginSession)
    return (
      <div>
        <Login />
      </div>
    );

  return (
    <div className="mainDivdashboard">
      <div className="notificaiondiv">
        <img
          className="notification_circledStyle"
          src={notification_circled}
          alt=""
        />
        {showDotIcon ?
          <span className="spanElipsedot">
            <img className="ElipsedoticonStyle" src={Elipsedot} alt="" />
          </span>
          :
          <></>
        }
      </div>
      {/* <div className="searchboxmainWrapper"> */}
      {/* <div class=" searchboxrow" style={{ backgroundColor: "" }}> */}
      {/* <input
            className="FindaPartnerInput"
            placeholder="Buscar un compañero"
            type="text"
            name=""
            id=""
          />
          <span className="searchi">
            <Search />
          </span>*/}
      {/* </div>  */}

      {/* <div class=" dropDownboxrow" style={{ backgroundColor: "" }}> */}
      {/* <Dropdown options={options1} placeholder="Mostrar último mes" no /> */}
      {/* <Calendar /> */}
      {/* <div className="dropdownArrow">
            <img src={arrowdropdown} alt="" />
          </div> */}
      {/* <div className="clander">
            <MaterialUIPicker />
            <DatePicker />
          </div> */}
      {/* </div> */}

      {/* </div> */}

      <div className="dashboardSecondmainDiv">
        <div className="container">
          <div className="row">
            <h4 className="col-sm-10 col-md-7 welcomBack" data-testid="welcomBack">¡Hola! Es momento de dar tu Voz.</h4>
            <div className="col-sm-2 col-md-5">
              <Calendar setDataRange={setDataRange} dataRange={dataRange} />
            </div>
          </div>
        </div>
      </div>
      <div className="dashboardSecondmainDiv">
        <div className="container">
          <div className="row">
            <div className="col-md-8" style={{ backgroundColor: "" }}>
              <AlertComponent />
            </div>
            <div className="col-md-4" style={{ backgroundColor: "" }}>
              <MonthComp cargandoTest={true} />
            </div>
          </div>
        </div>
      </div>
      <div className="divChart">
        <div className="container">
          <div className="row">
            {cargando ?
              <div className="col-md" style={{ backgroundColor: "" }}>
                <div className="divFlotanteVacio">
                  <div className="divFlotanteVacioAlineacion2">
                  </div>
                </div>
              </div>
              :
              <YourSkills totalCompetenciaCategoriaLen={totalCompetenciaCategoriaLen} />
            }
            <NewGraph dataSource={totalCompetenciaCategoria} />
          </div>
        </div>
      </div>
      <div className="dashboardThirdmainDiv">
        <div className="container">
          <div className="row">

            <div className="col-md-9" style={{ backgroundColor: "" }}>
              <div className="SkillsDiv">
                {cargando
                  ?
                  <StrongestSkillsLoader />
                  :
                  <StrongestSkills competencias={totalCompetencia} abilitiesArray={abilitiesArray} />
                }
              </div>
            </div>
            <div className="col-md-3" style={{ backgroundColor: "" }}>
              <div className="totalVoicesDiv">
                {cargando ?
                  <HistoricCompLoader />
                  :
                  <HistoricComp totalCompetenciaCategoriaLen={totalCompetenciaCategoriaLen}/>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="dashboardFourthMainDiv">
        <div className="container">
          <div className="row">
            <div className="abilitiesDiv">
              {cargando ?
                <>
                  <div className="yourOutstandingSkillsDiv">Resumen de tus habilidades</div>
                  <div className="outstandingSkillsDiv" style={{ backgroundColor: "#ffffff", borderBottomLeftRadius: "18px", borderBottomRightRadius: "18px" }}>
                    <YourAbilitiesLoader />
                  </div>
                </>
                :
                <YourAbilities totalCompetenciaCategoria={totalCompetenciaCategoria} abilitiesArray={abilitiesArray} />
              }
            </div>
          </div>
        </div>
      </div>
      <DrawerComp setShowDotIcon={setShowDotIcon} />
    </div>
  );
};
export default DashBoardComp;
