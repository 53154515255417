import React, { useState, useRef, useEffect } from "react";

// ICONS
import { ReactComponent as UpIcon } from "./assets/btn-open.svg";
import { ReactComponent as DownIcon } from "./assets/btn-close.svg";

import {
  wrapper,
  titleWrapper,
  expandWrapper,
  contentWrapper,
} from "./expand.module.css";

const ExpandableBox = (props) => {
  const { title, defaultOpen = false } = props;
  const [isExpand, setIsExpand] = useState(defaultOpen);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (isExpand) {
      setHeight(contentRef.current.scrollHeight);
    } else {
      setHeight(0);
    }
  }, [isExpand]);

  const expandHandler = () => {
    setIsExpand((prev) => !prev);
  };

  return (
    <div className={wrapper}>
      <button onClick={expandHandler} className={titleWrapper} id="button" data-testid="button">
        <span>{title}</span>
        {isExpand ? <DownIcon /> : <UpIcon />}
      </button>

      <div
        ref={contentRef}
        className={expandWrapper}
        style={{ height: height + "px" }}
      >
        <div className={contentWrapper}>{props.children}</div>
      </div>
    </div>
  );
};

export default ExpandableBox;
