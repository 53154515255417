import React, { useState, useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { vocesTotalesByTarget, vocesTotalesByOrigen, getUserById } from "../../../../dasboard/api";
import { vozavosContext } from "../../../../vozavosContext";
import VoiceTableInfo from "./VoiceTableInfo";
import classes from "./../shared.module.css";
import { Button } from 'reactstrap';
import Moment from 'react-moment';
import { getUserPhoto } from "../../../../../assets/api";
import MyLoader from "./MyLoader";
import { useParams } from 'react-router-dom';

const VoiceTable = (props) => {

    let { id } = useParams();
    const {
        state: { client, mail, userName }, //mail
    } = useContext(vozavosContext);

    const { palabra, tabIndex, dataRange, toggleDataPicker, cargandoTest, showDataPicker, categoria = "ALL" } = props;
    const [pageDar, setPageDar] = useState(0);
    const [listLengthDar, setListLengthDar] = useState(0);
    const [rowsPerPageDar, setRowsPerPageDar] = useState(5);
    const [showTableDar, setShowTableDar] = useState(true);
    const [showTableInfoDar, setShowTableInfoDar] = useState(false);
    const [idDar, setIdDar] = useState('');
    const [nombreVozDar, setNombreVozDar] = useState('');
    const [origenVozDar, setOrigenVozDar] = useState('');
    const [urlDar, setUrlDar] = useState('');
    const [timeVozDar, setTimeVozDar] = useState('');
    const [conversacionDar, setConversacionDar] = useState([]);

    
    const [pagePedir, setPagePedir] = useState(0);
    const [listLengthPedir, setListLengthPedir] = useState(0);
    const [rowsPerPagePedir, setRowsPerPagePedir] = useState(5);
    const [listVoces, setListVoces] = useState([]);
    const [showTablePedir, setShowTablePedir] = useState(true);
    const [showTableInfoPedir, setShowTableInfoPedir] = useState(false);
    const [idPedir, setIdPedir] = useState('');
    const [nombreVozPedir, setNombreVozPedir] = useState('');
    const [origenVozPedir, setOrigenVozPedir] = useState('');
    const [urlPedir, setUrlPedir] = useState('');
    const [timeVozPedir, setTimeVozPedir] = useState('');
    const [conversacionPedir, setConversacionPedir] = useState([]);

    const [cargando, setCargando] = useState(cargandoTest);
    const [localTabIndex, setLocalTabIndex] = useState(tabIndex);
    const [isVozDrawer, setIsVozDrawer] = useState(true);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        async function loadVoces1() {
            if (id && isVozDrawer) {
                setIsVozDrawer(false);
                var filter = {
                    "id": id
                };
                var voz = await getUserById(filter, userName, mail);
                var userPhoto = await getUserPhoto(voz.origen, voz.origenName, client);
                showTableInformation(id, voz.origenName, voz.origenName, userPhoto, true, new Date(voz.createdAt), voz.conversacion);
                setCargando(false);
            }
        }
        loadVoces1();
    }, [id, dataRange])

    useEffect(() => {
        async function loadVoces2() {
            var voces;

            if (!id) {
                if (!showDataPicker) toggleDataPicker(!showDataPicker);
                setShowTablePedir(true);
                setShowTableInfoPedir(true);
                setShowTableDar(true);
                setShowTableInfoDar(true);
                setCargando(true);
                if (listVoces.length <= 0) {
                    voces = await generateVocesList(0, 5, palabra);
                    setListVoces(voces);
                }
                else {
                    var page, rowsPerPage;
                    if (palabra === 'dada a ') {
                        page = pageDar;
                        rowsPerPage = rowsPerPageDar;
                    } else {
                        page = pagePedir;
                        rowsPerPage = rowsPerPagePedir;
                    }
                    voces = await generateVocesList(page * rowsPerPage, page * rowsPerPage + rowsPerPage, palabra);
                    setListVoces(voces);
                }

                //toggleAll();
                setCargando(false);
            }
        }
        loadVoces2();
    }, [palabra, pageDar, pagePedir, rowsPerPagePedir, rowsPerPageDar, id, dataRange])

    useEffect(() => {
        setRedirect(false);
        async function loadVoces3() {
            if (id && localTabIndex === tabIndex && !isVozDrawer) {
                setRedirect(true);
            }
        }
        loadVoces3();
    }, [tabIndex, id, dataRange])

    const toggleAll = () => {
        if (palabra === 'dada a ') {
            setShowTableDar(!showTableDar);
            setShowTableInfoDar(!showTableInfoDar);
        } else {
            setShowTablePedir(!showTablePedir);
            setShowTableInfoPedir(!showTableInfoPedir);
        }
        toggleDataPicker();
    }

    const handleChangePageDar = (event, newPage) => {
        setPageDar(newPage);
    };

    const handleChangeRowsPerPageDar = (event) => {
        setRowsPerPageDar(parseInt(event.target.value, 10));
        setPageDar(0);
    };

    const handleChangePagePedir = (event, newPage) => {
        setPagePedir(newPage);
    };

    const handleChangeRowsPerPagePedir = (event) => {
        setRowsPerPagePedir(parseInt(event.target.value, 10));
        setPagePedir(0);
    };

    const showTableInformation = (idVoz, origenVoz, nameVoz, url, band, time, conversacion) => {
        if (band) {
            setIdPedir(idVoz);
            setNombreVozPedir(origenVoz);
            setOrigenVozPedir(nameVoz);
            setTimeVozPedir(time);
            setUrlPedir(url);
            setShowTablePedir(!showTablePedir);
            setShowTableInfoPedir(!showTableInfoPedir);
            setConversacionPedir(conversacion);
        } else {
            if (palabra === 'dada a ') {
                setIdDar(idVoz);
                setNombreVozDar(nameVoz);
                setOrigenVozDar(origenVoz);
                setTimeVozDar(time);
                setUrlDar(url);
                setShowTableDar(!showTableDar);
                setShowTableInfoDar(!showTableInfoDar);
                setConversacionDar(conversacion);
            } else {
                setIdPedir(idVoz);
                setNombreVozPedir(nameVoz);
                setOrigenVozPedir(origenVoz);
                setTimeVozPedir(time);
                setUrlPedir(url);
                setShowTablePedir(!showTablePedir);
                setShowTableInfoPedir(!showTableInfoPedir);
                setConversacionDar(conversacion);
            }
        }
        toggleDataPicker();
    }

    const generateVocesList = async (inicio, fin, tipoVoz) => {
        let filter, vc;
        if (tipoVoz === 'dada a ') {
            filter = {
                origen: mail.toLowerCase()
            };
        } else {
            filter = {
                target: mail.toLowerCase()
            };
        }

        filter.sortDirection = "DESC";
        filter.filter = {
            "competenciaSolicitada": { "size": { "eq": 0 } }
        };

        if (dataRange.length > 0) {
            filter.createdAt = { "between": [dataRange[0], dataRange[1]] };
        }

        if (tipoVoz === 'dada a ') {
            vc = await vocesTotalesByOrigen(filter, userName, mail);
        } else {
            vc = await vocesTotalesByTarget(filter, userName, mail);
        }

        const vocesList = [];
        if (tipoVoz !== 'dada a ') {
            for (const item of vc.filter(voz => voz.origen !== mail.toLowerCase()).slice(inicio, fin)) {
                let urlPhoto = await getUserPhoto(item.origen === mail.toLowerCase() ? item.target : item.origen,
                    item.origen === mail.toLowerCase() ? item.targetName : item.origenName, client);
                vocesList.push({
                    origenVoz: item.origenName,
                    nameVoz: item.origen === mail.toLowerCase() ? item.targetName : item.origenName,
                    createdAt: item.createdAt,
                    idVoz: item.id,
                    url: urlPhoto,
                    tipoVoz: item.origen === mail.toLowerCase() ? 'dar' : 'pedir',
                    time: new Date(item.createdAt),
                });
            }
        }
        else {
            for (const item of vc.filter(voz => voz.origen === mail.toLowerCase()).slice(inicio, fin)) {
                let urlPhoto = await getUserPhoto(item.origen === mail.toLowerCase() ? item.target : item.origen,
                    item.origen === mail.toLowerCase() ? item.targetName : item.origenName, client);
                vocesList.push({
                    origenVoz: item.origenName,
                    nameVoz: item.origen === mail.toLowerCase() ? item.targetName : item.origenName,
                    createdAt: item.createdAt,
                    idVoz: item.id,
                    url: urlPhoto,
                    tipoVoz: item.origen === mail.toLowerCase() ? 'dar' : 'pedir',
                    time: new Date(item.createdAt),
                });
            }
        }

        setListLengthDar(vc.filter(voz => voz.origen === mail.toLowerCase()).length);
        setListLengthPedir(vc.filter(voz => voz.origen !== mail.toLowerCase()).length);
        return vocesList;
    }

    const renderTableData = (textoTipoVoz) => {
        return listVoces.map((filteredperson, index) => {
            const { idVoz, nameVoz, time, url, origenVoz } = filteredperson;
            return (
                <TableRow className={classes.fila} id={idVoz} key={index}>
                    <TableCell>
                        <div className={classes.iconUser}>
                            <img src={url} alt="profile" className={classes.photoCell} />
                        </div>
                    </TableCell>
                    <TableCell className={classes.srchTitleWraper}>
                        <p>Voz {palabra}:</p>
                        <p>{nameVoz}</p>
                    </TableCell>
                    <TableCell className={classes.dateTable}>
                        <p>Fecha</p>
                        <p className={classes.date}><Moment format="DD / MM / YYYY">{time}</Moment></p>
                    </TableCell>
                    <TableCell>
                        <Button className="pedirVozBtnFila" onClick={(e) => showTableInformation(idVoz, origenVoz, nameVoz, url, false, time, [])}>
                            <a className="pedirVozTexto">Ver</a>
                        </Button>
                    </TableCell>
                </TableRow >
            )
        }
        )
    }
    if (redirect) {
        return <Redirect push to="/myvoices" />
    }
    if (cargando) {
        return <MyLoader data-testid="loader" />
    } else {
        if (palabra === 'dada a ') {
            return (
                <>
                    {showTableDar ?
                        <TableContainer>
                            <Table aria-label="Tabla voces dadas">
                                <TableBody data-testid="tableBody">
                                    {renderTableData('dar')}
                                </TableBody>
                            </Table>
                            <TablePagination
                                labelRowsPerPage="Filas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={listLengthDar}
                                rowsPerPage={rowsPerPageDar}
                                page={pageDar}
                                onChangePage={handleChangePageDar}
                                onChangeRowsPerPage={handleChangeRowsPerPageDar}
                            />
                        </TableContainer>
                        :
                        <VoiceTableInfo categoria={categoria} palabra={palabra} cargandoTest={true} idRegistro={idDar} nombrePersona={nombreVozDar} origenVoz={origenVozDar} urlFoto={urlDar} toggle={toggleAll} mail={mail} userName={userName} time={timeVozDar} conversacion={conversacionDar} isVozDrawer={isVozDrawer} />
                    }

                </>
            );
        } else {
            return (
                <>  
                    {showTablePedir ?
                        <TableContainer>
                            <Table aria-label="Tabla voces recibidas">
                                <TableBody data-testid="pedirTable">
                                    {renderTableData('pedir')}
                                </TableBody>
                            </Table>
                            <TablePagination
                                labelRowsPerPage="Filas por página"
                                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                rowsPerPageOptions={[5, 10, 15]}
                                component="div"
                                count={listLengthPedir}
                                rowsPerPage={rowsPerPagePedir}
                                page={pagePedir}
                                onChangePage={handleChangePagePedir}
                                onChangeRowsPerPage={handleChangeRowsPerPagePedir}
                            />
                        </TableContainer>
                        :
                        <VoiceTableInfo categoria={categoria} palabra={palabra} cargandoTest={true} idRegistro={idPedir} nombrePersona={nombreVozPedir} origenVoz={origenVozPedir} urlFoto={urlPedir} toggle={toggleAll} userName={userName} time={timeVozPedir} conversacion={conversacionPedir} isVozDrawer={isVozDrawer} />
                    }
                </>
            );
        }
    }
}


export default VoiceTable;