import React from "react";
import classes from "./shared.module.css";
import Pill from "./Pill";

const PillsWrapper = (props) => {
  const { list = [], category, type, move } = props;
  return (
    <div className={classes.pillsWrapper}>
      {
        category === 'ALL' && type === '' ?
          list.map((obj, i) => {
            return <Pill key={obj.id} title={obj.title} id={obj.id} desc={obj.desc} move={move} />;
          })
          :
          list.map((obj, i) => {
            if (category === obj.category && type === obj.type) {
              return <Pill key={obj.id} title={obj.title} id={obj.id} desc={obj.desc} move={move} />;
            }
            return null;
          })
      }
    </div>
  );
};

export default PillsWrapper;
