import React from "react"
import ContentLoader from "react-content-loader"

const StrongestSkillsLoader = (props) => (
    <ContentLoader
        speed={2}
        width={743}
        height={150}
        viewBox="0 0 743 150"
        backgroundColor="#f3f3f3"
        foregroundColor="#dcdbdb"
        title="Cargando tus competencias más fuertes..."
        style={{ width: '100%' }}
        {...props}
    >
        <rect x="49" y="59" rx="3" ry="3" width="178" height="10" />
        <rect x="49" y="81" rx="3" ry="3" width="140" height="10" />
        <circle cx="364" cy="85" r="40" />
        <circle cx="505" cy="85" r="40" />
        <circle cx="646" cy="85" r="40" />F
    </ContentLoader>
)

export default StrongestSkillsLoader