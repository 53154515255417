import React from "react"
import ContentLoader from "react-content-loader"

const MyVoicesLoader = (props) => (
    <ContentLoader
        speed={2}
        width={1000}
        height={350}
        viewBox="0 0 1000 350"
        backgroundColor="#f3f3f3"
        foregroundColor="#dcdbdb"
        title="Cargando Voz..."
        style={{ width: '100%' }}
        {...props}
    >
        <rect x="3" y="173" rx="24" ry="24" width="180" height="40" />
        <rect x="47" y="128" rx="3" ry="3" width="88" height="6" />
        <circle cx="18" cy="128" r="12" />
        <rect x="81" y="32" rx="3" ry="3" width="88" height="6" />
        <rect x="81" y="50" rx="3" ry="3" width="200" height="6" />
        <circle cx="32" cy="44" r="27" />
        <rect x="217" y="173" rx="24" ry="24" width="140" height="40" />
        <rect x="393" y="173" rx="24" ry="24" width="174" height="40" />

    </ContentLoader>
)

export default MyVoicesLoader;
