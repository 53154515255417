import React, { useContext } from "react";
//////////////custom component /////////
import Sidebar from "../sidebar/sidebar";
import { Login } from "../../screens/Login/SignInCustom";
import { vozavosContext } from "../../components/vozavosContext";
////////////// <-->/////////////
// custom css////////
import "./../home/home.css";
import HomeComponent from "./homeComponent";
///////// <--> ///////
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import Dashboard from "./../dasboard/DashBoardComp";
import givevoice from "./../giveVoiceComp/giveVoice";
import askvoice from "./../giveVoiceComp/askVoice";
import myvoices from "./../giveVoiceComp/myvoices";
import PagNotFound from "../giveVoiceComp/Tabs/Errores/PagNotFound";
import PagServerError from "../giveVoiceComp/Tabs/Errores/PagServerError";
import VoiceTable from "../giveVoiceComp/Tabs/shared/MisVocesComponents/VoiceTable";
/////////////// <--> //////////////

const Home = () => {
  const {
    state: { loginSession },
  } = useContext(vozavosContext);
  //};
  if (!loginSession)
    return (
      <div>
        <Login />
      </div>
    );

  return (
    <BrowserRouter>
      <div id="mainWrapper">
        <HomeComponent />
        <div className="content-layout">
          <Sidebar />
          <Switch>
            <Route
              exact path="/"
              render={() => {
                return (
                  loginSession ?
                    <Redirect to="/dashboard" /> :
                  <Redirect to="/"/> 
                )
              }}
            />
            <Route path="/dashboard" data-testid ="dashboard" component={Dashboard} />
            <Route path="/givevoice/:id" component={givevoice} />
            <Route path="/givevoice/" component={givevoice} />
            <Route path="/askvoice" component={askvoice} />
            <Route path="/myvoices" component={myvoices} />
            <Route path="/voice/:id" component={myvoices} />

            <Route path="/er/" component={PagServerError} />
            <Route component={PagNotFound} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
};
export default Home;
