import React from "react";
import classes from "./tabbar.module.css";
import { ReactComponent as HappyIcon } from "./../assets/icon-happy.svg";
import { ReactComponent as ChatIcon } from "./../assets/icon-chat.svg";
const TabContent = (props) => {
  return (
    <div className={classes.tabContent}>
      <HappyIcon className={classes.happyIcon} />
      {props.children}
      <ChatIcon className={classes.chatIcon} />
    </div>
  );
};

export default TabContent;
