import React from 'react';
import classes from "./../shared.module.css";
import partyIconSrc from "./../../../assets/party-popper_1f389.png";
import winkingIconSrc from "./../../../assets/winking-face_1f609.png";
import bicepIconSrc from "./../../../assets/flexed-biceps_1f4aa.png";
import PillsWrapper from "../../shared/PillsWrapper";

const Competencias = (props) => {
    const { arrayExcelente, arrayBueno, arrayMejorar, arrayAdecuado, categoria } = props;

    return (
        <div className={classes.parentFlex}>
            {arrayExcelente.length > 0 ? 
                <>
                    <div className={classes.divFlex}>
                        <img
                            src={partyIconSrc}
                            className={classes.emoji}
                            alt="Party icon"
                        />
                        <p className={classes.tituloCompetenciaExcelente}>Experto</p>
                    </div>
                    <PillsWrapper list={arrayExcelente} category={categoria} type="" move="n" />
                </>
                : ""
            }

            {arrayBueno.length > 0 ?
                <>
                    <div className={classes.divFlex}>
                        <img
                            src={winkingIconSrc}
                            className={classes.emoji}
                            alt="Winking icon"
                        />
                        <p className={classes.tituloCompetenciaBueno}>Optimo</p>
                    </div>
                    <PillsWrapper list={arrayBueno} category={categoria} type="" move="n" />
                </>
                : ""
            }

            {arrayAdecuado.length > 0 ?
                <>
                    <div className={classes.divFlex}>
                        <img
                            src={bicepIconSrc}
                            className={classes.emoji}
                            alt="flexed bicep icon"
                        />
                        <p className={classes.tituloCompetenciaAdecuado}>Adecuado</p>
                    </div>
                    <PillsWrapper list={arrayAdecuado} category={categoria} type="" move="n" />
                </>
                : ""
            }

            {arrayMejorar.length > 0 ?
                <>
                    <div className={classes.divFlex}>
                        <p className={classes.tituloCompetenciaMejorar}>Para Desarrollar</p>
                    </div>
                    <PillsWrapper list={arrayMejorar} category={categoria} type="" move="n" />
                </>
                : ""
            }

        </div>
    );
}

export default Competencias;