export const listVocesByTarget = /* GraphQL */ `
  query ListVocesByTarget(
    $target: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVozFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVocesByTarget(
      target: $target
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        competenciaExcelente
        competenciaBueno
        competenciaMejorar 
      }
      nextToken
    }
  }
`;

export const listVocesTotalesByTarget = /* GraphQL */ `
  query ListVocesByTarget(
    $target: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVozFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVocesByTarget(
      target: $target
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        origen
        target
        origenName
        targetName
        createdAt
        competenciaSolicitada
      }
      nextToken
    }
  }
`;

export const listVocesTotalesByOrigen = /* GraphQL */ `
  query ListVocesByOrigen(
    $origen: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVozFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVocesByOrigen(
      origen: $origen
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        origen
        target
        origenName
        targetName
        createdAt
      }
      nextToken
    }
  }
`;

export const detalleMisVoces = /* GraphQL */ `
  query GetVoz($id: ID!) {
    getVoz(id: $id) {
      id
      origen
      target
      origenName
      targetName
      comentario
      competenciaExcelente
      competenciaBueno
      competenciaAdecuado
      competenciaMejorar
      competenciaSolicitada
      anexos
      conversacion {
          name
          creacion
          mensaje
        }
    }
  }
  `;
export const obtenerNombre = /* GraphQL */ `
  query GetVoz($id: ID!) {
    getVoz(id: $id) {
      origen
      origenName
      target
      targetName
      createdAt
      conversacion {
          name
          creacion
          mensaje
        }
    }
  }
  `;
export const listCompetenciaDetalle = /* GraphQL */ `
  query ListCompetencias(
    $filter: ModelCompetenciaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompetencias(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        nombre
        descripcion
        tipo
        categoria
      }
      nextToken
    }
  }
`;