import React from "react";
import circleBlueD from "./../../home/assets/circulo.svg";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import Zoom from '@material-ui/core/Zoom';

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 22,
        borderRadius: 20,
    },
    colorPrimary: {
        backgroundColor: "#4D4CAC",
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#49B9E3',
    },
}))(LinearProgress);

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: 'rgba(222, 227, 255, 1)',
        color: 'rgba(41, 39, 48, 1)',
        boxShadow: theme.shadows[1],
        borderRadius: 10,
        fontSize: 12,
        padding: 12,
    },
}))(Tooltip);

const YourAbilities = (props) => {

    const { abilitiesArray } = props;
    let desc = "Estos números representan el conteo de tus habil  idades en cada una de las categorías de calificación.";

    const RenderAbilityRow = () => {
        return (
            <>
                {abilitiesArray.map((obj, index) => {
                let sum = Math.round(((obj.maleyoung * 2 + obj.malemiddle * 4 + obj.malehalf * 4 + obj.maleolder * 0.3) / (obj.maleyoung + obj.malemiddle + obj.malehalf + obj.maleolder)) * 10) / 10;                
                let avg = (sum / 4) * 100; 
                let description = "";

                if (isNaN(sum)) {
                    sum = 0;
                    avg = 0;
                    description = "No tienes voces, te invitamos a pedir feedback.";
                } else if (sum <= 1.9) {
                    description = "¡Puedes hacerlo mejor!";
                } else if (sum <= 2.5) {
                    description = "Vas por buen camino, sigue fortaleciendo tu habilidad.";
                } else {
                    description = "Tu habilidad es Excelente ¡Sigue así!";
                }
                    return (
                        <>
                            <div className="outstandingSkillsDiv" key={index} id={index} style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "#f8f8f8", borderBottomLeftRadius: index === abilitiesArray.length - 1 ? "18px" : "", borderBottomRightRadius: index === abilitiesArray.length - 1 ? "18px" : "" }}>
                                <div className="yourOutstandingSkillsText">{obj.state}</div>
                                <div className="flexCompetencias">
                                    <div className="contenedoresCompetencias">
                                        <div className="courageText"><p className="descripcion">Experto</p></div>
                                        <div className="firstcircleBluesecond">
                                            <img className="circleBlueSecond" src={circleBlueD} alt="" />
                                            <div className="blueCirclecount"><p className="valorCompetencia">{obj.maleyoung}</p></div>
                                        </div>
                                    </div>
                                    <div className="contenedoresCompetencias">
                                        <div className="ColaborationText"><p className="descripcion">Optimo</p></div>
                                        <div className="SecondcircleDiv">
                                            <img className="secondCircle" src={circleBlueD} alt="" />
                                            <div className="secondCicleCount"><p className="valorCompetencia">{obj.malemiddle}</p></div>
                                        </div>
                                    </div>

                                    <div className="contenedoresCompetencias">
                                        <div className="inovationText"><p className="descripcion">Adecuado</p></div>
                                        <div className="thirdcircleDiv">
                                            <img className="thirdcircle" src={circleBlueD} alt="" />
                                            <div className="thirdcircleCount"><p className="valorCompetencia">{obj.malehalf}</p></div>
                                        </div>
                                    </div>

                                    <div className="contenedoresCompetencias">
                                        <div className="inovationText"><p className="descripcion">Puedes mejorar</p></div>
                                        <div className="thirdcircleDiv">
                                            <img className="thirdcircle" src={circleBlueD} alt="" />
                                            <div className="thirdcircleCount"><p className="valorCompetencia">{obj.maleolder}</p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="avgSkills">
                                    <div className="avgSkillsText">Promedio de desempeño</div>
                                    <div className="flexTusCompetencias">
                                        <div className="wowSkillsText" data-testid="wowSkillsText">{description}</div>
                                        <div className="avgSkillsNumber" data-testid="avgSkillsNumber">{sum}</div>
                                    </div>
                                    <div className="linearProgressBar">
                                        <BorderLinearProgress variant="determinate" value={avg} />
                                        <div className="divRangeProgressBar">
                                            <p>0</p><span className="rangeProgressBar"></span><p>4</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
                }
            </>
        )
    };

    return (
        <>
            <LightTooltip title={desc} arrow TransitionComponent={Zoom}>
                <div className="yourOutstandingSkillsDiv">Resumen de tus habilidades</div>
            </LightTooltip>
            <RenderAbilityRow />
        </>
    );
}

export default YourAbilities;