import React, { useContext, useEffect } from "react";
import "./SingInCustom.css";
import logo from "../../assets/logo-intro.svg";
import { vozavosContext } from "../../components/vozavosContext";
import * as Msal from "msal";
import { MSALAuthenticationProviderOptions, ImplicitMSALAuthenticationProvider, } from "@microsoft/microsoft-graph-client/lib/src/browser";
import { Client } from "@microsoft/microsoft-graph-client";

var conf = require("../../components/Authenticator/authConfig");
var jwt = require('jsonwebtoken');

export function Login() {
  const {
    state: { loginSession },
    actions: { setloginSession, setUserName, setMail, setMsal, setClient }
  } = useContext(vozavosContext);

  const msalApplication = new Msal.UserAgentApplication(conf.msalConfig);
  useEffect(() => {
    async function loadLogin() {

      const options = new MSALAuthenticationProviderOptions(conf.graphScopes);
      const msalProvider = new ImplicitMSALAuthenticationProvider(msalApplication, options);
      const AuthOptions = {
        authProvider: msalProvider,
      };
      const Client1 = Client;
      var client = Client1.initWithMiddleware(AuthOptions);
      setClient(client);
    }
    if (localStorage.getItem('msal.idtoken') != null) {
      setloginSession(!loginSession)
      setMsal(msalApplication)
      var token = localStorage.getItem('msal.idtoken');
      decodeIdtoken(token);
    }
    loadLogin();
  }, [])

  const decodeIdtoken = (token) => {
    var tokenDecoded = jwt.decode(token);
    setUserName(tokenDecoded.name)
    setMail(tokenDecoded.preferred_username)
  }

  async function signIn(msalApplicationVar) {
    let session;
    if (!msalApplicationVar.getAccount()) {
      return await msalApplicationVar.loginPopup(conf.loginRequest)
    }
    return session;
  }

  const initSession = async () => {
    try {
      if (await signIn(msalApplication)) {
        setloginSession(!loginSession)
        setUserName(msalApplication.account.name);
        setMail(msalApplication.account.userName);
        setMsal(msalApplication);
      }
    } catch (err) {
      // console.log("Error > ", err)
      // await apiSplunk(userName, mail, err, true);
    }
  }

  return (
    <>
      <div className="containerlogin">
        <div className="row h-100">
          <div className="col-sm-12 my-auto">
            <div className="card card-signin my-5 card-block mx-auto">
              <div className="text-center">
                <br></br>
                <br></br>
                <img className="logo-login" src={logo} alt="" /> <br></br>
                <br></br>
                <button
                  type="button"
                  className="btn btn-outline-login"
                  onClick={() => initSession()}
                  id="login"
                  name="login"
                  data-testid="login"
                >
                  Iniciar sesión
                </button>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
