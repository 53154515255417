import React, { useContext } from "react";
import { vozavosContext } from "../../../vozavosContext";
import { NavLink, Redirect } from "react-router-dom";
import error404 from "./assets/error-404.svg";
import "./pagError.css";

const PagNotFound = () => {
    const {
        state: { renderDashboard },
        actions: { setRenderDashboard },
    } = useContext(vozavosContext);

    const redirectDashboard = () => {
        setRenderDashboard(!renderDashboard);
        return <Redirect push to="/dashboard" />
    };

    return (
        <div className="container parentDiv">
            <div className="row flexPageError">
                <div className="col-lg-6 col-md-6 col-sm-12 flexImg">
                    <img alt="página no encontrada" src={error404}></img>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 flexTxt">
                    <p>Oops!</p>
                    <p>Al parecer la página que buscas no está disponible, prueba de nuevo volviendo al Dashboard:</p>
                    <button data-testid="botonDashboard" id="botonDashboard" className="dashboardBtn" onClick={() => redirectDashboard()}>
                        <NavLink to="/dashboard">Ir al Dashboard</NavLink>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PagNotFound;