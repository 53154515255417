import React, { Fragment, useState, useRef, useEffect, useContext } from "react";
import { useParams } from 'react-router-dom';
import classes from "./darvoz.module.css";
import PillsWrapper from "../shared/PillsWrapper";
import CheckBoxPill from "../shared/CheckBoxPill";
import ListContainerDesktop from "./../shared/ListContainer";
import SubmitButtonsWrapper from "../shared/SubmitButtonsWrapper";
import { useSkillList } from "./../../context/useSkillList";
import MobileListContainer from "../shared/MobileListContainer";
import msgSrc from "./../../assets/envelope_2709.png";
import FieldShowTitle from "./../shared/FieldShowTitle";
import classes2 from "./../shared/shared.module.css";
import classes3 from "./../shared/searchWrapper.module.css";
import presentIconSrc from "./../../assets/wrapped-present_1f381.png";
import PeoplePicker from "../../../SearchBar/PeoplePicker";
import { Element, scroller } from 'react-scroll';
import { ReactComponent as SearchIcon } from "./../../assets/search-24px.svg";
import { detalleVoz } from "../../../dasboard/api";
import Comentario from "../shared/MisVocesComponents/Comentario";
import { vozavosContext } from "../../../vozavosContext";
import "./tabDarVoz.css";

const DarVozTab = (props) => {
  let { id } = useParams();
  const {
    state: { mail, userName, client },
  } = useContext(vozavosContext);
  const { isCheckPillActiveProps } = props;
  const [isCheckPillActive, setCheckPill] = useState(isCheckPillActiveProps);
  const [isCheckPillActiveSkill, setCheckPillSkill] = useState(isCheckPillActiveProps);
  const { skillList, resetSpecialSkills, resetSkills, setRegalo, getArrayLength } = useSkillList();
  const [str, setStr] = useState("");
  const [gift, setGift] = useState("");
  const [peopleSelected, setPeopleSelected] = useState("");
  const [companero, setCompanero] = useState(null);

  const [errorCompannero, setErrorCompannero] = useState("");
  const [errorCompetencias, setErrorCompetencias] = useState("");
  const [errorComentario, setErrorComentario] = useState("");
  const [errorRegalo, setErrorRegalo] = useState("");

  //const [listRequest, setListRequest] = useState([]);
  const [originName, setOriginName] = useState('');
  const [originMail, setOriginMail] = useState('');
  const [originComment, setOriginComment] = useState('');
  const [boolComment, setBoolComment] = useState(false);

  useEffect(() => {

    async function loadDetailVoice() {
      if (id) {
        let voces = await getDetailVoice();
        setOriginName(voces.origenName);
        setOriginMail(voces.email);
        if (voces.comentario.trim().length > 4) {
          setBoolComment(true)
          setOriginComment(voces.comentario);
        }
      }
    }
    loadDetailVoice();
  }, [peopleSelected]);

  const getDetailVoice = async () => {
    var filter = {
      "id": id
    };
    var vc = await detalleVoz(filter, userName, mail);
    return vc;
  }

  const wrapperRef = useRef();
  function setTextArea(value) {
    setStr(value);
    if (value !== '')
      setErrorComentario('');
  }
  function setTextGift(value) {
    setGift(value);
    setRegalo(value);
    if (value === '')
      setErrorRegalo('');
  }

  const validateTextAreaMessage = () => {
    if (getArrayLength)
      setErrorCompetencias('');
  };


  const checkPillHandlerSkills = () => {
    const chkSkill = !isCheckPillActiveSkill;
    setCheckPillSkill(chkSkill);
    if (!chkSkill) {
      resetSkills();
    }
  }

  const clicMethod = (scrollTo) => {
    scroller.scrollTo(scrollTo);
  }
  const [toggleState, setToggleState] = useState(1);const toggleTab = (index) => {setToggleState(index)};

  return (
    <div className={classes.wrapper} data-testid="wrapper">
      <p className={classes.titlePara}>{id ? "Voz solicitada por:" : "¿A qué compañero quieres darle una Voz?"}</p>
      <Element name="errorCompannero">
        <div ref={wrapperRef} className={classes3.searchInputWrapper}>
          <PeoplePicker originMail={originMail} originName={originName} setPeopleSelected={setPeopleSelected} arrayCompanero={companero}
            fnErrorCompannero={setErrorCompannero} limit={1} />
          <SearchIcon />
        </div>
        <p>
          <span name="errorPeoplePicker" className={classes.error}>{errorCompannero}</span>
        </p>
      </Element>
      {id && boolComment ?
        <Comentario palabra={"recibida de"} comentario={originComment} anexo={''} />
        :
        <></>
      }
      <Element name="errorCompetencias">
        <p className={classes.pillsTitle}>
          Para dar tu Voz arrastra y suelta las competencias en cada nivel de calificación según lo consideres:
        </p>
        <PillsWrapper list={skillList} category={id ? "ALL" : "N/A"} type={id ? '' : "N/A"} move="y" />
        {id ?
          <></>
          :
          <><p className={classes.pillsTitle}>¿Esta persona pertenece a alguno de los siguientes equipos?</p></>
        }
        <div className="container1"><div className="bloc-tabs1">
            <button className={toggleState===1? "tabs1 active-tabs1":"tabs1"} onClick={() => toggleTab(1)}>Equipo Comercial</button><button className={toggleState===2? "tabs1 active-tabs1":"tabs1"} onClick={() => toggleTab(2)}>Equipo de Servicios</button></div>
        <div className="content1-tabs">
          <div className={toggleState === 1 ? "content1  active-content1" : "content1"}>
          <PillsWrapper list={skillList} category="CHECKBOX" type="SPECIAL" move='y' /></div>
          <div className={toggleState === 2 ? "content1 active-content1" : "content1"}>
          <PillsWrapper list={skillList} category="N/A" type="SERVICIO" move='y'/></div>
          </div></div>
        {
          id ?
            <></>
            :
            <>
              <CheckBoxPill
                isActive={isCheckPillActiveSkill}
                checkPillHandler={checkPillHandlerSkills}
                title='¿Quieres calificar una habilidad?'
              />
              {isCheckPillActiveSkill && (
                <PillsWrapper list={skillList} category="CHECKBOX" type="ACTIVE-SKILL" move="y" />
              )}
            </>
        }
        <MobileListContainer isCheckPillActive={isCheckPillActive} skillList={skillList} />
        {/* LIST CONTAINER HAS 3-SETS  */}
        <ListContainerDesktop skillList={skillList} validateTextAreaMessage={validateTextAreaMessage} idExist={id ? true : false} />
        <p>
          <span name="errorListContainer" className={classes.error}>{errorCompetencias}</span>
        </p>
      </Element>
      <Fragment>
        <FieldShowTitle
          title="Sería genial que dejaras un comentario para tu compañero"
          iconSrc={msgSrc}
        />
        <div className={classes2.contenedorComentario}>
          <textarea
            className={`${classes2.inputSendFields} styleScroll ${str.length === 1500 && classes2.commentTxtAreaFull
              }`}
            type="textarea"
            role="textareaComentario"
            placeholder="Escribe aquí el comentario que quieras dejarle a tu compañero acerca de la Voz que le estás dando."
            rows="4"
            style={{ color: "black" }}
            value={str}
            onChange={({ target: { value } }) => setTextArea(value)}
            maxLength={1500}
          />
          <Element name="errorComentario">
            <span name="errorComentarioSpan" style={{
              float: "left",
              textalign: "left",
              font: "12px/19px CabinRegular",
              letterspacing: "0px",
              color: "#A51A1A",
              opacity: "1",
            }}
            >{errorComentario}</span>
          </Element>
          <span
            style={{
              float: "right",
              letterSpacing: "2px",
              color: "#888888",
              fontWeight: "500",
            }}
          >
            {str.length}/1500
          </span>
        </div>
      </Fragment>

      <Fragment>
        <FieldShowTitle
          title="Último paso, ¿Qué tal si le regalas a tu compañero algo para su desarrollo?"
          iconSrc={presentIconSrc}
        />
        <textarea
          className={`${classes2.inputSendFields} styleScroll ${gift.length === 3000 && classes2.commentTxtAreaFull
            }`}
          type="textarea"
          role="textareaRegalo"
          placeholder="Pega aquí el link de una página web, de un vídeo o de un documento que pueda ser de su interés."
          rows="3"
          value={gift}
          onChange={({ target: { value } }) => setTextGift(value)}
          style={{ color: gift.search("http") !== -1 ? "#4132b4" : "black" }}
        />
      </Fragment>
      <Element name="errorRegalo">
        <span name="errorRegaloSpan" className={classes.error}>{errorRegalo}</span>
      </Element>
      <SubmitButtonsWrapper typeButton="darvoz" setCheckPill={setCheckPill} setCheckPillSkill={setCheckPillSkill}
        setComment={setStr} comment={str} setTextGift={setGift} setCompanero={setCompanero}
        fnErrorCompetencias={setErrorCompetencias} fnErrorCompannero={setErrorCompannero}
        fnErrorComentario={setErrorComentario} fnErrorRegalo={setErrorRegalo}
        peopleSelected={peopleSelected} gift={gift} scrollTo={clicMethod} id={id} client={client}
      />
    </div>
  );
};

export default DarVozTab;
