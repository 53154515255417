import React from "react";
import classes from "./tabbar.module.css";

const TabItem = (props) => {
  const { onClick, ownClass = "" } = props;
  return (
    <div
      className={`${classes.tabItem} ${ownClass} ${
        props.isActive && classes.tabItemActive
      }`}
      // aria-isActive={props.isActive}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
};

export default TabItem;
