import React from "react";
import classes from "./shared.module.css";
import SkillsList from "./SkillsList";

import partyIconSrc from "./../../assets/party-popper_1f389.png";


const ListItem = (props) => {
  const { skillList, onDelete,validateTextAreaMessage } = props;

  return (
    // <div className={classes.skillListContainer}>
    <div className={classes.listItemOne}>
        <div className={classes.skillListContainer}>
            <SkillsList
                category="competencias"
                list={skillList}
                onDelete={onDelete}
                listTitle="Competencias"
                color="#4132b4"
                iconSrc={partyIconSrc}
                validateTextAreaMessage ={validateTextAreaMessage}
            />
        </div>
    </div>
  );
};

export default ListItem;
