import React, { Fragment, useState, useRef } from "react";
import classes from "../PedirVozTab/pedirvoz.module.css";
import classes2 from "./../shared/shared.module.css";
import classes3 from "./../shared/searchWrapper.module.css";
import "./tabPedirVoz.css";
import PillsWrapper from "../shared/PillsWrapper";
import CheckBoxPill from "../shared/CheckBoxPill";
import ListContainerDesktop from "./../shared/ListContainerOne";
import SubmitButtonsWrapper from "../shared/SubmitButtonsWrapper";
import FieldShowTitle from "./../shared/FieldShowTitle";
import msgSrc from "./../../assets/envelope_2709.png";
import { useSkillList } from "./../../context/useSkillList";
import MobileListContainer from "../shared/MobileListContainer";
import PeoplePicker from "../../../SearchBar/PeoplePicker";
import { ReactComponent as SearchIcon } from "./../../assets/search-24px.svg";
import { Element, scroller } from 'react-scroll';
// import ProfileBox from "../shared/profileBox";

const PedirVozTab = (props) => {
  const { isCheckPillActiveDarVozT } = props;
  const [isCheckPillActiveDarVoz, setCheckPillDarVoz] = useState(isCheckPillActiveDarVozT);
  const [isCheckPillActiveSkill, setCheckPillSkill] = useState(isCheckPillActiveDarVozT);
  const { skillList, resetSpecialSkills, resetSkills, getArrayLengthDarVoz } = useSkillList();
  const [companeroDarVoz, setCompaneroDarVoz] = useState(null);
  const [peopleSelected, setPeopleSelected] = useState([]);

  const [strDarVoz, setStrDarVoz] = useState("");
  const [errorCompanneroDarVoz, setErrorCompanneroDarVoz] = useState("");
  const [errorCompetenciasDarVoz, setErrorCompetenciasDarVoz] = useState("");
  const [errorComentarioDarVoz, setErrorComentarioDarVoz] = useState("");
  const [toggleState, setToggleState] = useState(1);

  const wrapperRef = useRef();
  function setTextAreaDarVoz(value) {
    setStrDarVoz(value);
    if (value !== '')
      setErrorComentarioDarVoz('');
  }

  const validateTextAreaMessageDarVoz = () => {
    if (getArrayLengthDarVoz)
      setErrorCompetenciasDarVoz('');
  };
  const checkPillHandlerSkills = () => {
    const chkSkill = !isCheckPillActiveSkill;
    setCheckPillSkill(chkSkill);
    if (!chkSkill) {
      resetSkills();
    }
  }
  const clicMethod = (scrollTo) => {
    scroller.scrollTo(scrollTo);
  }
 
  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className={classes.wrapper}>
      <p className={classes.titlePara}>¿A qué compañero(s) quieres pedirle(s) una Voz?</p>
      <Element name="errorCompannero">
        <div ref={wrapperRef} className={classes3.searchInputWrapper}>
          <PeoplePicker originMail={""} originName={""} setPeopleSelected={setPeopleSelected} arrayCompanero={companeroDarVoz}
            fnErrorCompannero={setErrorCompanneroDarVoz} limit={5} />
          <SearchIcon />
        </div>
        <p>
          <span className={classes.error}>{errorCompanneroDarVoz}</span>
        </p>
      </Element>
      <Element name="errorCompetencias">
        <p className={classes.pillsTitle}>
          Para pedir la Voz arrastra y suelta las competencias en el siguiente campo:
        </p>
        <PillsWrapper list={skillList} category="N/A" type="N/A" move="y" />

        <p className={classes.pillsTitle}>¿Perteneces a alguno de los siguientes equipos?
        </p>
        <div className="container2">
          <div className="bloc-tabs2">
            <button className={toggleState === 1 ? "tabs2 active-tabs2" : "tabs2"} onClick={() => toggleTab(1)}>
              Equipo Comercial
            </button>
            <button
              className={toggleState === 2 ? "tabs2 active-tabs2" : "tabs2"}
              onClick={() => toggleTab(2)}
            >
              Equipo de Servicios
            </button>
          </div>

          <div className="content2-tabs">
            <div
              className={toggleState === 1 ? "content2  active-content2" : "content2"}
            >
          <PillsWrapper list={skillList} category="CHECKBOX" type="SPECIAL" move='y' />
            </div>

            <div
              className={toggleState === 2 ? "content2 active-content2" : "content2"}
            >
            <PillsWrapper list={skillList} category="N/A" type="SERVICIO" move='y' />
            </div>
          </div>
        </div>

        <CheckBoxPill
          isActive={isCheckPillActiveSkill}
          checkPillHandler={checkPillHandlerSkills}
          title='¿Quieres pedir una Voz sobre una habilidad?'
        />
        {isCheckPillActiveSkill && (
          <PillsWrapper list={skillList} category="CHECKBOX" type="ACTIVE-SKILL" move='y' />
        )}
        <MobileListContainer isCheckPillActiveDarVoz={isCheckPillActiveDarVoz} skillList={skillList} />
        {/* LIST CONTAINER HAS 3-SETS */}
        <ListContainerDesktop skillList={skillList} validateTextAreaMessage={validateTextAreaMessageDarVoz} />
        <p>
          <span className={classes.error}>{errorCompetenciasDarVoz}</span>
        </p>
      </Element>

      <Fragment>
        <FieldShowTitle
          title="Sería genial que dejaras un comentario para tu compañero"
          iconSrc={msgSrc}
        />
        <div className={classes2.contenedorComentario}>
          <textarea
            className={`${classes2.inputSendFields} styleScroll ${strDarVoz.length === 1500 && classes2.commentTxtAreaFull
              }`}
            // data-isFull={comment == 3000}
            type="textarea"
            role="textareaComentario"
            placeholder="Escribe aquí por qué quieres recibir su Voz. "
            rows="4"
            style={{ color: "black" }}
            value={strDarVoz}
            onChange={({ target: { value } }) => setTextAreaDarVoz(value)}
            maxLength={1500}
          /><span style={{
            float: "left",
            textalign: "left",
            font: "12px/19px CabinRegular",
            letterspacing: "0px",
            color: "#A51A1A",
            opacity: "1",
          }}
          >{errorComentarioDarVoz}</span>
          <span
            style={{
              float: "right",
              letterSpacing: "2px",
              color: "#888888",
              fontWeight: "500",
            }}
          >
            {strDarVoz.length}/1500
          </span>
        </div>
      </Fragment>
      <SubmitButtonsWrapper typeButton="pedirVoz" setCheckPill={setCheckPillDarVoz} setCheckPillSkill={setCheckPillSkill}
        setComment={setStrDarVoz} comment={strDarVoz} setCompanero={setCompaneroDarVoz}
        fnErrorCompetencias={setErrorCompetenciasDarVoz} fnErrorCompannero={setErrorCompanneroDarVoz}
        fnErrorComentario={setErrorComentarioDarVoz}
        peopleSelected={peopleSelected} scrollTo={clicMethod}
      />
    </div>
  );
};

export default PedirVozTab;
