import React from "react";
import classes from "./shared.module.css";
import SkillsList from "./SkillsList";

import partyIconSrc from "./../../assets/party-popper_1f389.png";
import winkingIconSrc from "./../../assets/winking-face_1f609.png";
import bicepIconSrc from "./../../assets/flexed-biceps_1f4aa.png";

const ListItem = (props) => {
  const { skillList, onDelete, validateTextAreaMessage } = props;

  return (
    <div className={classes.skillListContainer}>
      <SkillsList
        category="excelente"
        list={skillList}
        onDelete={onDelete}
        listTitle="Experto"
        color="#4132b4"
        iconSrc={partyIconSrc}
        validateTextAreaMessage={validateTextAreaMessage}
        // idExist={idExist}
      />
      <SkillsList
        category="bueno"
        list={skillList}
        onDelete={onDelete}
        listTitle="Optimo"
        color="#5D7FF1"
        iconSrc={winkingIconSrc}
        validateTextAreaMessage={validateTextAreaMessage}
        // idExist={idExist}
      />
      <SkillsList
        category="adecuado"
        list={skillList}
        onDelete={onDelete}
        listTitle="Adecuado"
        color="#FE9900"
        iconSrc={bicepIconSrc}
        validateTextAreaMessage={validateTextAreaMessage}
        // idExist={idExist}
      />
      <SkillsList
        category="puedes"
        list={skillList}
        onDelete={onDelete}
        listTitle="Para Desarrollar"
        color="#FF808B"
        iconSrc={bicepIconSrc}
        validateTextAreaMessage={validateTextAreaMessage}
        // idExist={idExist}
      />
      
    </div>
  );
};

export default ListItem;
