import React from "react";
import faceCompe from "./../../home/assets/Grupo 1159.svg";

const YourSkills = (props) => {
  const { totalCompetenciaCategoriaLen } = props;

  if (totalCompetenciaCategoriaLen.excelente === 0 && totalCompetenciaCategoriaLen.bueno === 0 && totalCompetenciaCategoriaLen.adecuado === 0 && totalCompetenciaCategoriaLen.mejorar === 0) {
    return (
      <div className="col-md" style={{ backgroundColor: "" }}>
        <div className="divFlotanteVacio">
          <div className="divFlotanteVacioAlineacion1">
            <div className="txtCompetenciaBarVacio">
              <p className="descripcion derecho">Apenas recibas tu primera Voz, empezarás a ver cómo han calificado tus competencias</p>
            </div>
            <img src={faceCompe} alt="" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="col-md" data-testid="contenedorTusCompetencias" style={{ backgroundColor: "" }}>
        <div className="divFlotanteVacio">
          <div className="divFlotanteVacioAlineacion2">
            <div className="contenedorTusCompetencias">
              <div className="flexTusCompetencias">
                <div className="contenedorTusCompetencias">
                  <div className="competencia eCount">{totalCompetenciaCategoriaLen.excelente}</div>
                  <div className="eText">Experto</div>
                </div>
                <div className="contenedorTusCompetencias">
                  <div className="competencia bCount">{totalCompetenciaCategoriaLen.bueno}</div>
                  <div className="bText">Optimo</div>
                </div>
                <div className="contenedorTusCompetencias">
                  <div className="competencia pCount">{totalCompetenciaCategoriaLen.adecuado}</div>
                  <div className="pText">Adecuado</div>
                  </div>
                <div className="contenedorTusCompetencias">
                  <div className="competencia pCount">{totalCompetenciaCategoriaLen.mejorar}</div>
                  <div className="pText">Para desarrollar</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YourSkills;