import React, { useState } from "react";
import { vozavosContext } from "./components/vozavosContext";
import Home from "./components/home/home";

const App = () => {
  const [loginSession, setloginSession] = useState(false);
  const [userName, setUserName] = useState("");
  const [mail, setMail] = useState("");
  const [msal, setMsal] = useState();
  const [client, setClient] = useState();
  const [renderDashboard, setRenderDashboard] = useState(false);
  const [renderGiveVoice, setRenderGiveVoice] = useState(false);
  const [renderAskVoice, setRenderAskVoice] = useState(false);
  const [renderMyVoices, setRenderMyVoices] = useState(false);
  const [renderMyVoicesReceived, setRenderMyVoicesReceived] = useState(false);

  return (
    <vozavosContext.Provider
      value={{
        state: { loginSession, userName, mail, msal, client, renderGiveVoice, renderDashboard, renderAskVoice, renderMyVoices, renderMyVoicesReceived },
        actions: { setloginSession, setUserName, setMail, setMsal, setClient, setRenderDashboard, setRenderGiveVoice, setRenderAskVoice, setRenderMyVoices, setRenderMyVoicesReceived },
      }}
    >
      <div>
        <Home />
      </div>
    </vozavosContext.Provider>
  );
};

export default App;
