// import { UserAgentApplication } from "msal";

const msalConfig = {
  auth: {
    clientId: "7c4d95b2-95c8-49db-931f-cbe906661eec",
    authority:
      "https://login.microsoftonline.com/f47dcce4-02e7-4d88-a659-a2d0bbe170e7",
    redirectUri:"https://www.vozavos.com.co", //"http://localhost:3000" - "https://www.vozavos.com.co" process.env.REACT_APP_URL_LOGIN
  },
  cache: {
    //localStorage -  revisar  / sessionStorage
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const loginRequest = {
  scopes: ["openid", "profile", "User.Read"],
};
// Add here scopes for access token to be used at MS Graph API endpoints.
const tokenRequest = {
  scopes: ["Mail.Read"],
};

const graphScopes = ["Group.Read.All", "People.Read.All", "User.Read"];

//const msalApplication = ""; //new UserAgentApplication(msalConfig);

module.exports = {
  graphScopes,
  loginRequest,
  tokenRequest,
  msalConfig,
};
