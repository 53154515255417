import { v4 } from "uuid";
import Amplify, { API, graphqlOperation } from "aws-amplify";
import awsconfig from "../../aws-exports";
import { listCompetencias } from "../../graphql/queries";
import { detalleMisVoces, listCompetenciaDetalle, obtenerNombre, listVocesByTarget, listVocesTotalesByTarget, listVocesTotalesByOrigen } from "../../graphql/queries_custom";
import { apiSplunk } from "../../ApiLogicApp";

Amplify.configure(awsconfig);

let errorDynamo = "One or more parameter values are not valid. A value specified for a secondary index key is not supported."

export const obtenerCompetencias = async (id, userName, mail) => {
  try {
    if (id) {
      var filter = {
        "id": id
      };
      const apiData = await API.graphql(graphqlOperation(detalleMisVoces, filter));
      return await formarPills(apiData.data.getVoz.competenciaSolicitada);
    }
    else {
      const apiData = await API.graphql(graphqlOperation(listCompetencias))
      const competencias = apiData.data.listCompetencias.items;
      const competenciasList = [];

      for (var i = 0; i < competencias.length; i++) {
        competenciasList.push({
          id: v4(),
          title: competencias[i].nombre,
          category: competencias[i].categoria,
          type: competencias[i].tipo,
          desc: competencias[i].descripcion
        });
      }
      return competenciasList;
    }
  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'Error obtenerCompetencias');
  }
}

// export const obtenerTips = async () => {
//   try {
//     const apiData = await API.graphql(graphqlOperation(tips))
//     const tips = apiData.data.tips.items;
//     return tips;
//   } catch (err) {
//     await apiSplunk(userName, mail, err, true, 'Error obtenerTips');
//   }
// }

export const vocesRecibidas = async (variables, userName, mail, items = []) => {
  try {
    const apiData = await API.graphql(graphqlOperation(listVocesByTarget, variables));
    const voces = apiData.data.listVocesByTarget;
    const newItems = await [...items, ...voces.items];
    if (!voces.nextToken) {
      return newItems.length;
    }
    variables['nextToken'] = voces.nextToken;
    return vocesRecibidas(variables, userName, mail, newItems);
  } catch (err) {
    if (!err.errors[0].message.includes(errorDynamo))
      await apiSplunk(userName, mail, err, true, 'Error vocesRecibidas');
  }
}

export const vocesTotalesByTarget = async (variables, userName, mail, items = []) => {
  try {
    const apiData = await API.graphql(graphqlOperation(listVocesTotalesByTarget, variables));
    const vocesListTotal = apiData.data.listVocesByTarget;
    const newItems = await [...items, ...vocesListTotal.items];
    if (!vocesListTotal.nextToken) {
      return newItems;
    }
    variables['nextToken'] = vocesListTotal.nextToken;
    return vocesTotalesByTarget(variables, userName, mail, newItems);

  } catch (err) {
    if (!err.errors[0].message.includes(errorDynamo))
      await apiSplunk(userName, mail, err, true, 'Error vocesTotalesByTarget');
  }
}

export const vocesTotalesByOrigen = async (variables, userName, mail, items = []) => {
  try {
    const apiData = await API.graphql(graphqlOperation(listVocesTotalesByOrigen, variables));
    const vocesListTotal = apiData.data.listVocesByOrigen;
    const newItems = await [...items, ...vocesListTotal.items];
    if (!vocesListTotal.nextToken) {
      return newItems;
    }
    variables['nextToken'] = vocesListTotal.nextToken;
    return vocesTotalesByOrigen(variables, userName, mail, newItems);

  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'Error vocesTotalesByOrigen');
  }
}

export const getUserById = async (variables, userName, mail) => {
  try {
    const apiData = await API.graphql(graphqlOperation(obtenerNombre, variables));
    const vozListInfo = apiData.data.getVoz;
    return vozListInfo;
  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'Error getUserById');
  }
}

export const detalleVoz = async (variables, userName, mail) => {
  var arrayExcelente = [];
  var arrayBueno = [];
  var arrayMejorar = [];
  var arrayAdecuado = [];
  try {
    const apiData = await API.graphql(graphqlOperation(detalleMisVoces, variables));
    const vozListInfo = apiData.data.getVoz;
    if (vozListInfo.competenciaExcelente)
      arrayExcelente = await formarPills(vozListInfo.competenciaExcelente, userName, mail);
    if (vozListInfo.competenciaBueno)
      arrayBueno = await formarPills(vozListInfo.competenciaBueno, userName, mail);
    if (vozListInfo.competenciaAdecuado)
      arrayAdecuado = await formarPills(vozListInfo.competenciaAdecuado, userName, mail);
    if (vozListInfo.competenciaMejorar)
      arrayMejorar = await formarPills(vozListInfo.competenciaMejorar, userName, mail);
    let variable = {
      competenciaExcelente: arrayExcelente,
      competenciaBueno: arrayBueno,
      competenciaAdecuado: arrayAdecuado,
      competenciaMejorar: arrayMejorar,
      origenName: apiData.data.getVoz.origenName,
      targetName: apiData.data.getVoz.targetName,
      email: apiData.data.getVoz.origen,
      emailTarget: apiData.data.getVoz.target,
      comentario: vozListInfo.comentario,
      anexos: vozListInfo.anexos,
      conversacion: vozListInfo.conversacion
    };
    return variable;
  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'Error detalleVoz');
  }
}

const formarPills = async (vozListInfo, userName, mail) => {
  var array = [];
  for (var i = 0; i < vozListInfo.length; i++) {
    let filter = {
      filter: {
        "and": [
          {
            "nombre": { "eq": vozListInfo[i] }
          }
        ]
      }
    };
    let descripcion = await competenciaDescripcion(filter, userName, mail);
    array.push({
      id: v4(),
      title: descripcion.nombre,
      category: descripcion.categoria,
      type: descripcion.tipo,
      desc: descripcion.descripcion
    });
  }
  return array;
}

const competenciaDescripcion = async (variables, userName, mail) => {
  try {
    const apiData = await API.graphql(graphqlOperation(listCompetenciaDetalle, variables));
    return apiData.data.listCompetencias.items[0];
  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'Error competenciaDescripcion');
  }
}

const competenciasConsulta = async (variables, userName, mail, items = []) => {
  try {
    const apiData = await API.graphql(graphqlOperation(listVocesByTarget, variables));
    const voces = apiData.data.listVocesByTarget;
    const newItems = await [...items, ...voces.items];
    if (!voces.nextToken) {
      return newItems;
    }
    variables['nextToken'] = voces.nextToken;
    return await competenciasConsulta(variables, userName, mail, newItems);
  } catch (err) {
    await apiSplunk(userName, mail, err, true, 'ErrorcompetenciasConsulta');
  }
}

export const competenciasFuertes = async (competencias, abilitiesArray) => {
  var orden = { "m1": { "key": "", "value": 0 }, "m2": { "key": "", "value": 0 }, "m3": { "key": "", "value": 0 }, "m4": { "key": "", "value": 0 } };

  var totalSumaCompetencias = 0;
  let jsonToArray = Object.entries(competencias.totalCompetenciasOrden).filter(([key]) => !abilitiesArray.includes(key));
  var total = Object.fromEntries(jsonToArray);
  var array = [];
  for (var clave in total) {
    array.push({
      clave: clave,
      valor: total[clave]
    });
  }
  array.sort(function (a, b) {
    if (a.valor > b.valor) return -1;
    if (a.valor < b.valor) return 1;
    return 0;
  });

  let i = 0;
  for (const item of array) {
    if (i < 4 && item != null)
      totalSumaCompetencias += item.valor;
    i++;
  }
  if (array[0] != null) {
    orden.m1.key = array[0].clave;
    orden.m1.value = Math.round((array[0].valor / totalSumaCompetencias) * 100);
  }
  if (array[1] != null) {
    orden.m2.key = array[1].clave;
    orden.m2.value = Math.round((array[1].valor / totalSumaCompetencias) * 100);
  }
  if (array[2] != null) {
    orden.m3.key = array[2].clave;
    orden.m3.value = Math.round((array[2].valor / totalSumaCompetencias) * 100);
  }
  if (array[3] != null) {
    orden.m4.key = array[3].clave;
    orden.m4.value = Math.round((array[3].valor / totalSumaCompetencias) * 100);
  }
  return orden;
};

export const competenciasGraph = async (variables, userName, mail, skillArray) => {
  var array = await competenciasConsulta(variables, userName, mail);
  var total = {};
  var CE = 1, CB = 1, CM = 1, CN = 1;
  var totalCompetenciaCategoria = [];
  var totalCompetenciasLongitud = { "excelente": 0, "bueno": 0, "mejorar": 0, "adecuado": 0 };
  array.forEach(function (a) {
    if (a.competenciaExcelente) {
      for (let i = 0; i < a.competenciaExcelente.length; i++) {
        if (!skillArray.includes(a.competenciaExcelente[i]))
          totalCompetenciasLongitud.excelente += 1;
        if (a.competenciaExcelente[i] in total) {
          total[a.competenciaExcelente[i]] += CE;
          totalCompetenciaCategoria.forEach(function (b) {
            if (b.state === a.competenciaExcelente[i]) {
              b.maleyoung += CE;
            }
          });
        }
        else {
          let key = a.competenciaExcelente[i];
          total[key] = CE;
          totalCompetenciaCategoria.push(
            {
              state: key,
              maleyoung: CE,
              malemiddle: 0,
              malehalf: 0,
              maleolder: 0
            }
          )
        }
      }
    }

    if (a.competenciaBueno) {
      for (let i = 0; i < a.competenciaBueno.length; i++) {
        if (!skillArray.includes(a.competenciaBueno[i]))
          totalCompetenciasLongitud.bueno += 1;
        if (a.competenciaBueno[i] in total) {
          total[a.competenciaBueno[i]] += CB;
          totalCompetenciaCategoria.forEach(function (b) {
            if (b.state === a.competenciaBueno[i]) {
              b.malemiddle += CB;
            }
          });
        }
        else {
          let key = a.competenciaBueno[i]
          total[key] = CB;
          totalCompetenciaCategoria.push(
            {
              state: key,
              maleyoung: 0,
              malemiddle: CB,
              malehalf: 0,
              maleolder: 0
            }
          )
        }
      }
    }

    if (a.competenciaAdecuado) {
      for (let i = 0; i < a.competenciaAdecuado.length; i++) {
        if (!skillArray.includes(a.competenciaAdecuado[i]))
          totalCompetenciasLongitud.adecuado += 1;
        if (a.competenciaAdecuado[i] in total) {
          total[a.competenciaAdecuado[i]] += CN;
          totalCompetenciaCategoria.forEach(function (b) {
            if (b.state === a.competenciaAdecuado[i]) {
              b.malehalf += CN;
            }
          });
        }
        else {
          let key = a.competenciaAdecuado[i]
          total[key] = CN;
          totalCompetenciaCategoria.push(
            {
              state: key,
              maleyoung: 0,
              malemiddle: 0,
              malehalf: CN,
              maleolder: 0
            }
          )
        }
      }
    }

    if (a.competenciaMejorar) {
      for (let i = 0; i < a.competenciaMejorar.length; i++) {
        if (!skillArray.includes(a.competenciaMejorar[i]))
          totalCompetenciasLongitud.mejorar += 1;
        if (a.competenciaMejorar[i] in total) {
          total[a.competenciaMejorar[i]] += CM;
          totalCompetenciaCategoria.forEach(function (b) {
            if (b.state === a.competenciaMejorar[i]) {
              b.maleolder += CM;
            }
          });
        }
        else {
          let key = a.competenciaMejorar[i]
          total[key] = CM;
          totalCompetenciaCategoria.push(
            {
              state: key,
              maleyoung: 0,
              malemiddle: 0,
              malehalf: 0,
              maleolder: CM
            }
          )
        }
      }
    }

  });
  totalCompetenciaCategoria.sort(function (a, b) {
    let sumA = a.maleyoung * 10 + a.malemiddle * 5 + a.maleolder + a.malehalf * 2 - a.malemiddle * 6 - a.maleolder * 3;
    let sumB = b.maleyoung * 10 + b.malemiddle * 5 + b.maleolder + b.malehalf * 2 - b.malemiddle * 6 - b.maleolder * 3;
    if (sumA > sumB) return -1;
    if (sumA < sumB) return 1;
    return 0;
  });
  let competencias = {
    "totalCompetenciaCategoria": totalCompetenciaCategoria,
    "totalCompetenciasLongitud": totalCompetenciasLongitud,
    "totalCompetenciasOrden": total
  }

  return competencias;
};

