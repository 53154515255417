import React, { useState } from "react";
import classes from "../PedirVozTab/pedirvoz.module.css";
import VoiceTable from "../shared/MisVocesComponents/VoiceTable";
import Calendar from '../shared/Calendar';

const VocesTab = (props) => {
  const { tabIndex } = props;
  const [dataRange, setDataRange] = useState([]);
  const [showDataPicker, setShowDataPicker] = useState(true);
  const toggleDataPicker = () => setShowDataPicker(!showDataPicker);

  return (
    <div className={classes.wrapper}>
      <div className={classes.box}>
        <p className={classes.titlePara}>Voces {tabIndex === 1 ? "dadas a" : "recibidas de"} tus compañeros</p>
        {showDataPicker ? <Calendar setDataRange={setDataRange} dataRange={dataRange} /> : ""}
      </div>
      <VoiceTable palabra={tabIndex === 1 ? "dada a " : "recibida de "} tabIndex={tabIndex} dataRange={dataRange} toggleDataPicker={toggleDataPicker} showDataPicker={showDataPicker} cargandoTest={true}  />
    </div>
  );
};

export default VocesTab;
